import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import {
  Box,
  ThemeProvider,
  createTheme,
  ThemeOptions,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { csn } from '@one-vision/utils';
import { logRender } from '@one-vision/utils';
import { BackgroundInit } from '../../assets';
import { AfLazyLoadImage } from '../shared-components';
import { AfTosStep } from '../af-steps/af-tos-step';
import { AfMembershipStep } from '../af-steps/af-membership-step';
import { AfCheckOutStep } from '../af-steps/af-checkout-step';
import { AfVerifyEmailStep } from '../af-steps/af-verify-email';
import { AfFooter } from './footer';
import { useAfSelector } from '../../core/redux';
import { Step as StepEnum } from '../../core/redux/slices/step.redux';
import { AfSnackbar } from '../shared-components/af-snackbar/index';
import {
  theme,
  standardParams,
  themeCustomParams,
} from '../../core/theme';
import { AfConfirmationStep } from '../af-steps/af-confirmation-step';
import { useStyles } from './af-root.styles';
import { thunks, actions } from '../../core/redux';
import { StepperSkeleton } from './stepper-skeleton';
import { StepperRoot } from './stepper';
import { isSubscriptionInvalid } from 'core/utils';
import { afConfig } from 'core/config';
import { Af404Page } from 'components/af-404-page';
import { API } from 'core/api';
import {
  AfAsyncDispatch,
  PartnerGroupRule,
  TopLevelProjectStage,
} from 'core/types';

const stripePromise = loadStripe(afConfig.stripeKey || '');

const win = window as Window &
  typeof globalThis & {
    activationFlowInit: Promise<undefined> | undefined;
  };

export enum FormStep {
  TOS = 0,
  Membership = 1,
  Payment = 2,
  Confirmation = 4,
  Verify = 6,
}

export const AfRootView: React.FC = () => {
  logRender(AfRootView);

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState<FormStep>(FormStep.TOS);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [error, setError] = useState(false);

  win.activationFlowInit &&
    win.activationFlowInit.then(() => {
      setRender(true);
    });

  const { footerIsShown } = useAfSelector((state) => state.footer);
  const { step } = useAfSelector((state) => state.step);

  const activationFlowData = useAfSelector((state) => state.afData);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      ref.current &&
      ref.current.clientWidth <= themeCustomParams.af.breakpoints.sm
    ) {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [step]);

  const dispatch = useDispatch<AfAsyncDispatch>();

  const [themeCustom, setThemeCustom] = useState(theme);

  useEffect(() => {
    if (!render) {
      return;
    }

    (async () => {
      let accessToProvisionProjects = false;
      try {
        const { data } = await API.getPartnerConfig();

        const rules = data.data.map((el) => ({
          partnerGroupRuleId: el.partnerGroupRuleId as PartnerGroupRule,
          value: el.value === true.toString(),
        }));

        accessToProvisionProjects =
          rules.find(
            (el) =>
              el.partnerGroupRuleId ===
              PartnerGroupRule.AccessToProvisionProjects,
          )?.value || false;

        dispatch(actions.updatePartnerConfig(rules));
      } catch {
        setError(true);
      }

      dispatch(thunks.getAfData())
        .unwrap()
        .then((data) => {
          setThemeCustom(
            createTheme({
              ...standardParams,
              ...themeCustomParams,
              colors: {
                ...themeCustomParams.colors,
                primary: data.primaryColor,
                secondary: data.secondaryColor,
              },
            } as ThemeOptions),
          );
          document.title = data.brandDisplayName || 'Client Activation';

          if (!accessToProvisionProjects) {
            dispatch(
              actions.updateData({
                topLevelProjectStage: TopLevelProjectStage.NONE,
              }),
            );
          }

          if (isSubscriptionInvalid(data) && !data.isTrialing) {
            toLastStep();
            return;
          }
          if (data.tosAccepted) {
            nextStep();
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
          win.activationFlowInit = undefined;
        });
    })();
  }, [render]);

  const previousStep = useCallback(() => {
    setActiveStep(activeStep - 1 === 3 ? 2 : activeStep - 1);
  }, [activeStep]);
  const membershipStep = useCallback(() => {
    setActiveStep(FormStep.Membership);
  }, [activeStep]);
  const nextStep = useCallback(() => {
    setActiveStep(activeStep + 1 === 3 ? 4 : activeStep + 1);
  }, [activeStep]);
  const toLastStep = useCallback(() => {
    setActiveStep(FormStep.Confirmation);
  }, [activeStep]);
  const toVerification = useCallback(() => {
    setActiveStep(FormStep.Verify);
  }, [activeStep]);

  const content = useMemo(() => {
    switch (activeStep) {
      case FormStep.TOS:
        return (
          <AfTosStep
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            nextStep={nextStep}
            verificationStep={toVerification}
          />
        );
      case FormStep.Membership:
        return (
          <AfMembershipStep nextStep={nextStep} lastStep={toLastStep} />
        );
      case FormStep.Payment:
        return (
          <Elements stripe={stripePromise}>
            <AfCheckOutStep
              nextStep={nextStep}
              previousStep={previousStep}
            />
          </Elements>
        );
      case FormStep.Confirmation:
        return <AfConfirmationStep membershipStep={membershipStep} />;
      case FormStep.Verify:
        return <AfVerifyEmailStep />;
      default:
        break;
    }
  }, [activeStep, isLoading, themeCustom]);

  const showStepper = useMemo(() => {
    if (activeStep !== FormStep.Verify && isLoading) {
      return <StepperSkeleton />;
    }
    if (activeStep !== FormStep.Verify && !isLoading) {
      return <StepperRoot step={step} activeStep={activeStep} />;
    }
    return null;
  }, [activeStep, isLoading, step]);

  if (!render) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeCustom}>
      {error ? (
        <Af404Page />
      ) : (
        <Box
          className={csn(
            classes.root,
            [classes.rootJustify, footerIsShown],
            [classes.rootTabletWholePage, step !== StepEnum.None],
          )}
        >
          <AfSnackbar />
          <AfLazyLoadImage
            className={csn(classes.bgImage, [
              classes.bgImageHidden,
              step !== StepEnum.None,
            ])}
            src={BackgroundInit}
            thumbnail={activationFlowData.backgroundImage}
          />
          <div
            className={csn(classes.contentWrapper, [
              classes.contentWrapperMargin,
              footerIsShown,
            ])}
          >
            <div
              ref={ref}
              className={csn(classes.content, [
                classes.tabletView,
                step !== StepEnum.None,
              ])}
            >
              {activeStep !== FormStep.Verify && (
                <Box className={classes.logoWrapper}>
                  <img
                    src={activationFlowData.logo}
                    className={classes.logoIcon}
                  />
                </Box>
              )}
              {showStepper}

              <Box height="100%" width="100%">
                {content}
              </Box>
            </div>
          </div>
          <AfFooter show={footerIsShown} />
        </Box>
      )}
    </ThemeProvider>
  );
};
