import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      faq: {
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.px2rem(20)} 10%`,
        backgroundColor: '#e3e3e3',
      },
      faqHeader: {
        color: theme.colors.onevisionBlack,
        fontSize: theme.px2rem(24),
        fontWeight: 600,
      },
      textChunk: {
        margin: `${theme.px2rem(15)} 0`,
      },
      question: {
        color: theme.colors.onevisionBlack,
        fontSize: theme.px2rem(14),
        fontWeight: 500,
      },
      answer: {
        color: theme.colors.onevisionBlack,
        fontSize: theme.px2rem(14),
        lineHeight: theme.px2rem(24),
      },
      answerBold: {
        fontWeight: 500,
      },
    };
  },
  { name: 'af-footer-text' },
);
