import React from 'react';
import { Box, InputBase, InputLabel, FormHelperText } from '@mui/material';
import { FormikContextType } from 'formik';
import { csn } from '@one-vision/utils';
import { useStyles } from './af-input.styles';

interface Props {
  // We don't know the type of the fields in the form
  // eslint-disable-next-line
  formik: FormikContextType<any>;
  name: string;
  label: string;
  type: 'email' | 'text' | 'number';
}

export const AfInputView: React.FC<Props> = ({
  formik,
  name,
  label,
  type,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <InputLabel htmlFor={name} className={classes.labelStyled}>
          {label}
        </InputLabel>
        <FormHelperText
          disabled={!formik.touched[name] && !formik.errors[name]}
          error={formik.touched[name] && !!formik.errors[name]}
          className={classes.formHelperText}
        >
          {formik.touched[name] && formik.errors[name]}
        </FormHelperText>
      </Box>
      <InputBase
        id={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        name={name}
        type={type}
        error={formik.touched[name] && !!formik.errors[name]}
        className={csn(classes.inputStyled)}
        classes={{ error: classes.errorInput }}
      />
    </Box>
  );
};
