import React from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import { Snackbar, Portal } from '@mui/material';

import { actions, useAfSelector } from '../../../core/redux';

export const AfSnackbarView: React.FC = () => {
  const dispatch = useDispatch();
  const { text, type } = useAfSelector((state) => state.snackbar);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(actions.closeSnackbar());
  };

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(text)}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type}
        >
          {text}
        </Alert>
      </Snackbar>
    </Portal>
  );
};
