import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import { logError, tryToFormatPhoneNumber } from '@one-vision/utils';
import { ActivationFlowData, TopLevelProjectStage } from '../../types';

const initialStateValue: ActivationFlowData = {
  partnerId: 0,
  brandDisplayName: '',
  litsDate: '',
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  addressId: '',
  topLevelProjectStage: TopLevelProjectStage.PROPOSAL,
  brandId: '',
  address1: '',
  address2: '',
  businessName: '',
  city: '',
  state: '',
  zip: 0,
  serviceLevel: '',
  tosAccepted: false,
  creditCardProvided: false,
  desiredMembership: '',
  cppExpDate: '',
  coupon: '',
  backgroundImage: '',
  buyNow: true,
  primaryColor: '#E9840E',
  secondaryColor: '#2196F3',
  annual: true,
  activeSubscription: false,
  subscriptionId: null,
  logo: '',
  membershipFeatures: [],
  productsOffered: [
    {
      ovbid: '',
      ovpid: 0,
      productId: 0,
      productName: '',
      productOffered: false,
      priceInCents: 0,
      pricePointId: '',
      buyNow: false,
      buyNowPriceInCents: 0,
      buyNowPricePointId: '',
      annualPriceInCents: 0,
      annualPricePointId: '',
      annualBuyNow: false,
      annualBuyNowInCents: 0,
      annualBuyNowPricePointId: '',
      callForPricing: false,
      createdAt: '',
      updatedAt: '',
      stripeProductId: '',
    },
  ],
  supportPhone: '',
  signaturePhone: '',
  supportEmail: '',
  signatureEmail: '',
  calendlyUrl: '',
  websiteUrl: '',
  tosUrl: '',
  recommendationEngine: true,
  callForPricingPhone: '',
  isTrialing: false,
};

export const getAfData = createAsyncThunk<ActivationFlowData>(
  'clientData/get',
  async () => {
    try {
      const { data } = await API.getActivationFlowData();
      return {
        ...data.data,
        primaryPhone: tryToFormatPhoneNumber(data.data.primaryPhone),
        membershipFeatures: data.data.membershipFeatures.sort(
          (first, second) => (first.sortOrder > second.sortOrder ? 1 : -1),
        ),
        productsOffered: data.data.productsOffered
          .sort((first, second) =>
            first.productId > second.productId ? 1 : -1,
          )
          .map((el) => ({
            ...el,
            taxPercent: Number(el.taxPercent) / 100,
          })),
      };
    } catch (error) {
      logError(error);
      throw error;
    }
  },
);

export const activationFlowDataSlice = createSlice({
  name: 'contact-info',
  initialState: initialStateValue,
  reducers: {
    updateData: (
      state,
      action: PayloadAction<Partial<ActivationFlowData>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {
    [getAfData.fulfilled.type]: (
      state: ActivationFlowData,
      action: PayloadAction<ActivationFlowData>,
    ) => {
      return { ...state, ...action.payload };
    },
  },
});
