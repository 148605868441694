import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      inputLine: {
        marginBottom: theme.px2rem(15),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          flexDirection: 'column',
        },
      },
      inputSingle: {
        width: '100%',
      },
      inputTriple: {
        width: '31%',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
          '&:not(last-of-type)': {
            marginBottom: theme.px2rem(15),
          },
        },
      },
      labelStyled: {
        marginBottom: theme.px2rem(3),
        fontSize: theme.px2rem(12),
        color: theme.colors.onevisionBlack80,
      },
      formHelperText: {
        marginTop: 0,
      },
      inputStyled: {
        backgroundColor: '#F5F5F5',
        borderRadius: '8px',
        padding: `${theme.px2rem(5)} ${theme.px2rem(10)}`,
        color: theme.colors.onevisionBlack80,
        height: theme.px2rem(50),
        lineHeight: theme.px2rem(48),
        fontWeight: 500,
        border: 'none',
        width: '100%',
      },
      select: {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
        '& .MuiSelect-filled': {
          paddingRight: theme.px2rem(10),
          paddingTop: theme.px2rem(10),
        },
        '& .MuiSelect-filled:focus': {
          backgroundColor: 'transparent',
        },
      },
      selectArrow: {
        marginRight: theme.px2rem(5),
      },
      errorSelect: {
        border: '1px solid #f44336',
      },
    };
  },
  { name: 'af-location-data' },
);
