import React, { useEffect, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Switch,
  Button,
  CircularProgress,
} from '@mui/material';
import { csn } from '@one-vision/utils';
import { useDispatch } from 'react-redux';
import {
  actions,
  useAfSelector,
  usePartnerConfigRule,
} from '../../../../../core/redux/';
import { AfGirlIcon } from '../../../../shared-components/af-icons';
import { Answers } from '../../../../../core/redux/slices/answers.redux';
import { Options } from '../../../../../core/redux/slices/membership-step.redux';
import { AfOptionButton } from './af-option-button';
import { AfChatFrame } from '../../../../shared-components/af-chat-frame';
import { Step } from '../../../../../core/redux/slices/step.redux';
import { AfFaqBody } from '../../../../af-root/footer/af-faq-text.view';
import { AfMobOptionButton } from './af-mob-option-button';
import {
  ActivationFlowData,
  Csm,
  PartnerGroupRule,
  ProductOffered,
  UpcomingInvoiceParams,
} from '../../../../../core/types';
import {
  YOU_CHOSE_TEXT,
  RECOMMENDED_PLAN_TEXT,
  CURRENT_PLAN,
} from '../../../../../core/constants';
import { checkMembership, pricePointIdHandler } from 'core/utils';
import { priceHandler, checkBuyNow } from 'core/utils';
import { API } from 'core/api';
import { useStyles } from './af-membership-plans.styles';
import { AFMembershipFeatureList } from './af-membership-feature-list';

const checkEssentials = (name: string) =>
  name === 'Essentials+' ? Csm['Essentials Plus'] : name;

const EXPIRES_ON = 'Offer expires';
const EXPIRES_ON_MEMBERSHIP_EXP =
  'Offer expires when your complimentary membership expires.';

export const ChooseMembershipView: React.FC<{
  question2: Answers;
  question3: Answers;
  nextStep: () => void;
  lastStep: () => void;
  data: ActivationFlowData;
}> = ({ question2, question3, nextStep, lastStep, data }) => {
  const classes = useStyles();

  const firstRenderOption = (subscription: string) => {
    const essential = data.productsOffered.find(
      (el) => el.productId === Options.Essential,
    );
    const priority = data.productsOffered.find(
      (el) => el.productId === Options.Priority,
    );
    const proactive = data.productsOffered.find(
      (el) => el.productId === Options.Proactive,
    );
    const signature =
      data.productsOffered.find(
        (el) => el.productId === Options.Signature,
      ) || null;

    switch (subscription) {
      case Csm['Essentials Plus']:
        return essential;
      case Csm.Priority:
        return priority;
      case Csm.Proactive:
        return proactive;
      case Csm.Signature:
        return signature;
      default:
        if (
          question3 === Answers.NoOrNever &&
          question2 === Answers.NoOrNever &&
          essential
        ) {
          return essential;
        }
        if (
          question3 === Answers.Sometimes &&
          question2 === Answers.NoOrNever &&
          priority
        ) {
          return priority;
        }
        if (
          question2 === Answers.YesOrAlways &&
          question3 !== Answers.YesOrAlways &&
          proactive
        ) {
          return proactive;
        }
        if (question3 === Answers.YesOrAlways && signature) {
          return signature;
        }
        if (question3 === Answers.YesOrAlways && !signature) {
          return proactive;
        }
        return priority || essential || proactive || signature;
    }
  };

  const { plan: chosenOffer } = useAfSelector(
    (state) => state.membershipSteps,
  );

  const { yearly: isYearly, subscriptionDetails } = useAfSelector(
    (state) => state.membershipSteps,
  );

  const accessToSubscriptionManagementPlus = usePartnerConfigRule(
    PartnerGroupRule.AccessToSubscriptionManagementPlus,
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const nextStepHandler = useCallback(async () => {
    if (data.activeSubscription && chosenOffer && subscriptionDetails) {
      setLoading(true);
      try {
        const {
          data: { data: paymentMethods },
        } = await API.getPaymentMethods({
          'customer-id': subscriptionDetails.customerId,
        });
        dispatch(
          actions.updateMembershipSteps({
            paymentMethods,
          }),
        );
      } catch {
        dispatch(
          actions.updateSnackbar({
            text: 'Can`t retrieve payment methods',
            type: 'error',
          }),
        );
      }

      const params: UpcomingInvoiceParams = {
        ['product-id']: chosenOffer.productId,
        ['price-point-id']: pricePointIdHandler(
          data,
          chosenOffer,
          isYearly,
        ),
      };

      try {
        const {
          data: { data: upcomingInvoice },
        } = await API.getUpcomingInvoice(params);
        dispatch(
          actions.updateMembershipSteps({
            upcomingInvoice,
          }),
        );
        nextStep();
      } catch {
        dispatch(
          actions.updateSnackbar({
            text: 'Can`t retrieve subscription data',
            type: 'error',
          }),
        );
      } finally {
        setLoading(false);
      }
      return;
    }
    nextStep();
  }, [
    nextStep,
    subscriptionDetails,
    isYearly,
    data,
    chosenOffer,
    dispatch,
    setLoading,
    loading,
  ]);

  const lastStepHandler = useCallback(() => {
    API.submitPayment({ signup: false });
    dispatch(
      actions.updateData({
        desiredMembership: Csm['Declined Membership'],
      }),
    );
    lastStep();
  }, [lastStep, dispatch]);

  useEffect(() => {
    dispatch(actions.updateFooter({ footerIsShown: true }));
    dispatch(actions.updateStep({ step: Step.Membership }));
    if (!chosenOffer) {
      dispatch(
        actions.updateMembershipSteps({
          plan: firstRenderOption(
            data.activeSubscription
              ? data.serviceLevel
              : data.desiredMembership,
          ),
        }),
      );
    }

    return () => {
      dispatch(actions.updateFooter({ footerIsShown: false }));
      dispatch(actions.updateStep({ step: Step.None }));
    };
  }, []);

  const yearlyHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        actions.updateMembershipSteps({ yearly: e.target.checked }),
      );
    },
    [isYearly],
  );

  const chatOpenHandler = useCallback(() => {
    dispatch(actions.updateFooter({ showChat: true }));
  }, [dispatch]);

  const subYearly = subscriptionDetails?.billingFrequency === '1-year';

  const accessToPreventChargeClientsPreLits = usePartnerConfigRule(
    PartnerGroupRule.PreventChargeClientsPreLits,
  );

  const showReject =
    !data.activeSubscription ||
    (data.activeSubscription && data.isTrialing);

  const navigation = useMemo(
    () => (
      <Box className={classes.navigation}>
        <Button
          disabled={
            loading ||
            (data.activeSubscription &&
              !!chosenOffer &&
              subYearly === isYearly &&
              data.serviceLevel ===
                checkEssentials(chosenOffer.productName)) ||
            data.productsOffered.find(
              (el) => el.productId === chosenOffer?.productId,
            )?.callForPricing
          }
          classes={{ root: classes.nextStepButton }}
          onClick={nextStepHandler}
        >
          {`Continue to Payment >`}
          {loading ? (
            <CircularProgress
              classes={{
                root: csn(classes.spinner, [classes.graySpinner, loading]),
              }}
              size="1rem"
            />
          ) : null}
        </Button>
        <Typography className={classes.navText}>
          No contracts. Cancel anytime.
        </Typography>
        {showReject && (
          <Button
            disabled={loading}
            classes={{ root: classes.lastStepButton }}
            onClick={lastStepHandler}
          >
            {' '}
            No thanks. I don’t want a membership.
          </Button>
        )}
      </Box>
    ),
    [nextStepHandler, lastStepHandler, data, chosenOffer, setLoading],
  );

  const recommendedText = useCallback(
    (product: ProductOffered) => {
      if (data.activeSubscription) {
        return CURRENT_PLAN;
      }
      return data.desiredMembership ===
        checkEssentials(product.productName)
        ? YOU_CHOSE_TEXT
        : RECOMMENDED_PLAN_TEXT;
    },
    [data],
  );

  const recommendedCheck = useCallback(
    (productOffer: ProductOffered) => {
      const currentName =
        productOffer.productName === 'Essentials+'
          ? Csm['Essentials Plus']
          : productOffer.productName;
      const isThereSignature = !!data.productsOffered.find(
        (el) => el.productName === Csm['Signature'],
      );
      if (data.activeSubscription) {
        return currentName === data.serviceLevel;
      }

      if (currentName === data.desiredMembership) {
        return true;
      }
      if (checkMembership(data.desiredMembership as Csm)) {
        return false;
      }
      if (
        currentName === Csm['Essentials Plus'] &&
        question3 === Answers.NoOrNever &&
        question2 === Answers.NoOrNever
      ) {
        return true;
      }
      if (
        currentName === Csm.Priority &&
        ((question3 === Answers.Sometimes &&
          question2 === Answers.NoOrNever) ||
          (question3 === Answers.nullable &&
            question2 === Answers.nullable))
      ) {
        return true;
      }
      if (
        (currentName === Csm.Proactive &&
          question2 === Answers.YesOrAlways &&
          question3 !== Answers.YesOrAlways) ||
        (currentName === Csm.Proactive &&
          question3 === Answers.YesOrAlways &&
          !isThereSignature)
      ) {
        return true;
      }
      if (
        currentName === Csm.Signature &&
        question3 === Answers.YesOrAlways
      ) {
        return true;
      }
      return false;
    },
    [data, question2, question3],
  );

  const callForPricingPhone =
    data.callForPricingPhone || data.supportPhone;

  return (
    <Box className={classes.intermediateStep}>
      <Typography variant="h3" className={classes.header}>
        Membership Plans
      </Typography>
      {data.buyNow &&
        checkBuyNow(
          data,
          EXPIRES_ON,
          EXPIRES_ON_MEMBERSHIP_EXP,
          accessToPreventChargeClientsPreLits,
        ) && (
          <Typography variant="h2" className={classes.headerText}>
            {`Prices below reflect a discounted rate. ${checkBuyNow(
              data,
              EXPIRES_ON,
              EXPIRES_ON_MEMBERSHIP_EXP,
              accessToPreventChargeClientsPreLits,
            )}`}
          </Typography>
        )}
      {data.annual && (
        <Box className={classes.switchWrapper}>
          <Box>Monthly</Box>
          <Switch
            classes={{
              track: classes.switchTrack,
              checked: classes.switchThumb,
            }}
            checked={isYearly}
            onChange={yearlyHandler}
          />
          <Box>Annual</Box>
        </Box>
      )}
      <Box className={classes.content}>
        {chosenOffer && (
          <Box className={csn(classes.featuresColumn)}>
            <Typography className={classes.featureTitle}>
              FEATURES
            </Typography>
            <AFMembershipFeatureList productOffer={chosenOffer} />
          </Box>
        )}
        <Box className={csn(classes.optionsColumn)}>
          {chosenOffer &&
            data.productsOffered.map((el, index) => (
              <AfOptionButton
                key={`${index}-option-button`}
                name={el.productName}
                fee={priceHandler(data, el, isYearly)}
                callForPricing={
                  el.callForPricing ? callForPricingPhone : null
                }
                isYearly={isYearly}
                active={chosenOffer.productId === el.productId}
                recommended={recommendedCheck(el)}
                recommendedText={recommendedText(el)}
                onClick={() =>
                  dispatch(actions.updateMembershipSteps({ plan: el }))
                }
              />
            ))}
        </Box>
        <Box className={classes.optionsMob}>
          {chosenOffer &&
            data.productsOffered.map((el, index) => (
              <AfMobOptionButton
                key={`${index}-option-mob-button`}
                name={el.productName}
                fee={priceHandler(data, el, isYearly)}
                callForPricing={
                  el.callForPricing ? callForPricingPhone : null
                }
                isYearly={isYearly}
                active={chosenOffer.productId === el.productId}
                productOffer={chosenOffer}
                recommended={recommendedCheck(el)}
                recommendedText={recommendedText(el)}
                onClick={() =>
                  dispatch(actions.updateMembershipSteps({ plan: el }))
                }
              />
            ))}
          {navigation}
        </Box>
      </Box>
      <div className={classes.divider} />(
      <Box
        className={csn(classes.footer, [
          classes.footerReversed,
          !accessToSubscriptionManagementPlus,
        ])}
      >
        {accessToSubscriptionManagementPlus && (
          <Box className={classes.footerLeft}>
            <Typography>Have questions?</Typography>
            <Typography>
              Schedule a 15-minute call with one of our team members.
            </Typography>
            <Box className={classes.girlIconWrapper}>
              <AfGirlIcon classes={{ root: classes.girlIcon }} />
              <Button
                classes={{ root: classes.chatButton }}
                onClick={chatOpenHandler}
              >
                Chat with a membership expert
              </Button>
            </Box>
            <Box className={classes.faqMobile}>
              <AfFaqBody classesCustom={classes} />
            </Box>
          </Box>
        )}
        <Box className={classes.wrapNav}>{navigation}</Box>
      </Box>
      )
      <AfChatFrame />
    </Box>
  );
};
