import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      featureLine: {
        fontSize: theme.px2rem(12),
        color: theme.colors.black,
        marginLeft: theme.spacing(2),
      },
      checkMark: {
        color: 'black',
      },
      featureLineContainer: {
        display: 'flex',
        marginBottom: theme.spacing(0.5),
      },
    };
  },
  { name: 'af-checkout-page-features' },
);
