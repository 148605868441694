import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, CircularProgress } from '@mui/material';
import { csn } from '@one-vision/utils';
import { actions, useAfSelector } from '../../../core/redux';
import { useStyles } from './af-chat-frame.styles';

export const AfChatFrameView: React.FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const afData = useAfSelector((state) => state.afData);

  const dispatch = useDispatch();

  const showChat = useAfSelector((state) => state.footer.showChat);

  const hideSpinner = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const chatCloseHandler = useCallback(() => {
    dispatch(actions.updateFooter({ showChat: false }));
    setLoading(true);
  }, [dispatch]);

  return (
    <Modal open={showChat} onClose={chatCloseHandler}>
      <div className={classes.chatView}>
        {loading && <CircularProgress />}
        <iframe
          className={csn(classes.frame, [classes.hideFrame, loading])}
          onLoad={hideSpinner}
          src={afData.calendlyUrl}
        ></iframe>
      </div>
    </Modal>
  );
};
