import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      optionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        height: theme.px2rem(85),
        width: '100%',
        marginBottom: theme.px2rem(10),
        '&:last-of-type': {
          margin: 0,
        },
        [theme.breakpoints.down('md')]: {
          height: theme.px2rem(95),
        },
      },
      optionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        padding: theme.px2rem(20),
        borderRadius: '20px',
        color: theme.colors.black,
        backgroundColor: '#fff',
        boxShadow: '1px 1px 12px rgba(0, 0, 0, 0.2)',
        zIndex: 2,
        width: '78%',
        height: '100%',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          backgroundColor: '#fff',
          filter: theme.brightness.brightness95,
        },
        [theme.breakpoints.down('md')]: {
          width: '85%',
        },
      },
      optionButtonActive: {
        color: '#fff',
        backgroundColor: theme.colors.secondary,
        '&:hover': {
          transform: 'scale(1)',
          backgroundColor: theme.colors.secondary,
          filter: theme.brightness.brightness90,
        },
      },
      optionButtonLeft: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: theme.px2rem(16),
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'flex-start',
          fontSize: theme.px2rem(14),
        },
        '& svg': {
          marginRight: theme.px2rem(10),
          [theme.breakpoints.down('md')]: {
            marginRight: theme.px2rem(10),
          },
        },
      },
      optionButtonRight: {
        display: 'flex',
        flexDirection: 'row',
        '& div:first-of-type': {
          fontSize: theme.px2rem(24),
          fontWeight: 700,
          [theme.breakpoints.down('md')]: {
            fontWeight: 500,
          },
        },
        '& div:last-of-type': {
          fontSize: theme.px2rem(14),
          display: 'flex',
          alignItems: 'flex-end',
          textTransform: 'lowercase',
          paddingBottom: theme.px2rem(8),
        },
      },
      optionButtonRightContact: {
        flexDirection: 'column',
        '& div:first-of-type': {
          fontSize: theme.px2rem(14),
        },
      },
      recommendationLabel: {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        backgroundColor: '#32CA94',
        boxShadow: '1px 1px 12px rgba(0, 0, 0, 0.2)',
        color: '#FFFFFF',
        height: '100%',
        width: '100%',
        borderRadius: '20px',
        textAlign: 'center',
      },
      recommend: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '22%',
        fontSize: theme.px2rem(11),
        marginLeft: 'auto',
        [theme.breakpoints.down('md')]: {
          width: '15%',
          '& > svg': {
            display: 'none',
          },
          '& > div': {
            transform: 'rotate(90deg)',
            fontSize: theme.px2rem(10),
            fontWeight: 700,
          },
        },
      },
    };
  },
  { name: 'af-membership-option-button' },
);
