import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      featureLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.px2rem(15),
        paddingLeft: theme.px2rem(5),
        [theme.breakpoints.down('md')]: {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        },
      },
      text: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        [theme.breakpoints.down('md')]: {
          fontSize: theme.px2rem(12),
        },
      },
      optionIsChecked: {
        color: theme.colors.secondary,
        marginRight: theme.px2rem(5),
      },
      optionIsUnchecked: {
        color: '#BDBDBD',
        marginRight: theme.px2rem(5),
      },
      additionalInfoIcon: {
        marginLeft: theme.px2rem(5),
        color: '#BDBDBD',
        position: 'relative',
        top: theme.px2rem(5),
      },
      tooltip: {
        backgroundColor: theme.colors.darkBlue,
      },
      tooltipArrow: {
        color: theme.colors.darkBlue,
      },
      textDiv: {
        fontSize: theme.px2rem(14),
        fontWeight: 400,
      },
    };
  },
  { name: 'af-membership-options-feature' },
);
