import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Checkbox, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { useFormik } from 'formik';
import { csn, getChanges } from '@one-vision/utils';
import { AfTOSIcon } from '../../shared-components/af-icons';
import { schemas } from '../../shared-components/af-forms';
import { AfInput } from '../../shared-components/af-input';
import { AfPhoneInput } from '../../shared-components/af-phone-input';
import { actions, useAfSelector } from '../../../core/redux';
import { AfAsyncDispatch, SubmitTOSPayload } from '../../../core/types';
import { useStyles } from './af-tos-step.styles';
import { API } from '../../../core/api';
import { AfTosSkeleton } from './af-tos-skeleton';

const EMAIL_PROCESSING_ERROR =
  'Sorry, we do not have this email address on file. Please try a different email or contact us if you need assistance.';

interface Props {
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
  nextStep: () => void;
  verificationStep: () => void;
}

export const AfTosStepView: React.FC<Props> = ({
  isLoading,
  setIsLoading,
  nextStep,
  verificationStep,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch<AfAsyncDispatch>();

  const [editMode, setEditMode] = useState(false);

  const initialValues = useAfSelector((state) => state.afData);

  const [userAgree, setUserAgree] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const validationSchema = schemas.clientData;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (clientData, formikHelpers) => {
      setIsLoading(true);
      const changes = getChanges(
        initialValues,
        clientData,
      ) as SubmitTOSPayload & { primaryPhone?: string };
      if ('primaryPhone' in changes) {
        changes.phone = changes.primaryPhone;
        delete changes.primaryPhone;
      }
      API.submitTOS(changes as SubmitTOSPayload)
        .then(() => {
          if (changes?.email) {
            verificationStep();
            return;
          }
          dispatch(actions.updateData({ tosAccepted: true }));
          setIsLoading(false);
          nextStep();
        })
        .catch(() => {
          formikHelpers.setFieldTouched('email', true);
          setEmailError(true);
          dispatch(
            actions.updateSnackbar({
              text: EMAIL_PROCESSING_ERROR,
              type: 'error',
            }),
          );
          setIsLoading(false);
        });
    },
    enableReinitialize: true,
  });

  // remove, after fix for setFieldError inside formik onSubmit
  useEffect(() => {
    if (emailError) {
      formik.setFieldError('email', 'Email not on file');
    }
  }, [emailError]);

  const submitStep = useCallback(() => formik.handleSubmit(), [formik]);

  return (
    <Box className={csn(classes.root, [classes.noMargin, isLoading])}>
      {isLoading ? (
        <AfTosSkeleton />
      ) : (
        <>
          <Box className={classes.header}>
            <Typography
              variant="h3"
              className={csn(classes.headerText, classes.headerBold)}
            >
              {initialValues.firstName
                ? `Welcome, ${initialValues.firstName}!`
                : 'Welcome!'}
            </Typography>
            <Typography
              variant="h3"
              className={csn(classes.headerText, classes.hideHeaderText)}
            >
              Let’s get you set up to receive support.
            </Typography>
            <Typography
              variant="h3"
              className={csn(classes.headerText, classes.headerTextMob)}
            >
              We look forward to working with you. First, let’s make sure
              you are set up to receive tech support.
            </Typography>
            <Typography variant="body1" className={classes.headerDetails}>
              Please verify your contact information to get started.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <AfTOSIcon classes={{ root: classes.tosIcon }} />
          </Box>
          <Box
            className={csn(classes.infoBlock, [
              classes.editModeInfoBlock,
              editMode,
            ])}
          >
            {editMode ? (
              <>
                <Box className={classes.inputLine}>
                  <Box className={classes.inputDouble}>
                    <AfInput
                      formik={formik}
                      label="First Name"
                      type="text"
                      name="firstName"
                    />
                  </Box>
                  <Box className={classes.inputDouble}>
                    <AfInput
                      formik={formik}
                      label="Last Name"
                      type="text"
                      name="lastName"
                    />
                  </Box>
                </Box>
                <Box className={classes.inputLine}>
                  <Box className={classes.inputSingle}>
                    <AfInput
                      formik={formik}
                      label="Email"
                      type="email"
                      name="email"
                    />
                  </Box>
                </Box>
                <Box className={classes.inputLine}>
                  <Box className={classes.inputSingle}>
                    <AfPhoneInput
                      formik={formik}
                      label="Phone"
                      name="primaryPhone"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box className={classes.infoBlockHeaderWrap}>
                  <Typography
                    className={classes.infoBlockHeader}
                    component="div"
                  >
                    Contact Information
                  </Typography>
                  <Button
                    className={classes.editButton}
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </Button>
                </Box>
                <Box display="flex" flexDirection="row">
                  <PersonIcon className={classes.infoBlockIcon} />
                  <Box>
                    <Typography
                      className={classes.infoBlockText}
                      component="div"
                    >{`${formik.values['firstName']} ${formik.values['lastName']}`}</Typography>
                    <Typography className={classes.infoBlockText}>
                      {formik.values['email']}
                    </Typography>
                    <Typography className={classes.infoBlockText}>
                      {formik.values['primaryPhone']}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box className={classes.infoBlock}>
            <Box className={classes.infoBlockHeaderWrap}>
              <Typography className={classes.infoBlockHeader}>
                Service Address
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <PinDropOutlinedIcon className={classes.infoBlockIcon} />
              <Box>
                {initialValues.businessName && (
                  <Typography className={classes.infoBlockText}>
                    {initialValues.businessName}
                  </Typography>
                )}
                <Typography className={classes.infoBlockText}>
                  {`${
                    initialValues.address1 && `${initialValues.address1}, `
                  }${initialValues.address2 || ''}`}
                </Typography>
                <Typography className={classes.infoBlockText}>
                  {`${initialValues.city}, ${initialValues.state} ${initialValues.zip}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.terms}>
            <Box display="flex" flexDirection="row">
              <Checkbox
                checked={userAgree}
                onChange={() => setUserAgree((prev) => !prev)}
                classes={{ root: csn([classes.checked, userAgree]) }}
              />
              <Box
                display="flex"
                alignItems="center"
                className={classes.textToTOS}
              >
                I agree with{'  '}
              </Box>
              <a
                target="_blank"
                rel="noreferrer"
                href={initialValues.tosUrl}
                className={classes.linkToTOS}
              >
                terms of service
              </a>
            </Box>
          </Box>
          <Button
            data-testid="submit-tos-button"
            classes={{ root: classes.mainButton }}
            disabled={!userAgree}
            onClick={submitStep}
          >
            LET`S GO
          </Button>
        </>
      )}
    </Box>
  );
};
