import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {},
      thumb: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        filter: 'blur(20px)',
        WebkitFilter: 'blur(20px)',
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: -1,
      },
      img: {
        objectFit: 'cover',
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        transition: theme.transitions.create('opacity', {
          duration: '1s',
        }),
      },
      imgHidden: {
        opacity: 0,
      },
    };
  },
  { name: 'lazy-load-image' },
);
