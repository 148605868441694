import React from 'react';
import { createRoot } from 'react-dom/client';
import ensureRoot from './utils/ensure-root';
import { Provider } from 'react-redux';
import { AfApp } from './components/af-app';
import { store } from './core/redux';
import { applyTheme } from './core/theme';
import {
  AfErrorBoundary,
  AfAppLevelFallback,
} from './components/af-error-boundary';
import './assets';

const ThemedErrorBoundary = applyTheme(AfErrorBoundary);

const ThemedApp = applyTheme(AfApp);
const container = ensureRoot('root');
const root = createRoot(container);

root.render(
  <ThemedErrorBoundary fallback={<AfAppLevelFallback />}>
    <Provider store={store}>
      <ThemedApp />
    </Provider>
  </ThemedErrorBoundary>,
);
