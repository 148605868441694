import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles((theme: ExtTheme) => {
  return {
    root: {
      zIndex: 1,
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100%',
      position: 'relative',
      height: '100%',
    },
    bgImage: {
      backgroundColor: '#fff',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: -1,
      [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
        display: 'none',
      },
    },
    wrapper: {
      background: 'rgba(255, 255, 255, 0.85)',
      boxShadow:
        '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
      borderRadius: '20px',
      width: '50%',
      height: '50%',
      display: 'flex',
      flexDirection: 'column',
      padding: `${theme.px2rem(15)} ${theme.px2rem(10)}`,
      alignItems: 'center',
      [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
        width: '100%',
        boxShadow: 'none',
      },
      '& > svg': {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: theme.px2rem(250),
        },
      },
    },
    textWrapper: {
      marginTop: theme.px2rem(20),
      [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
        marginTop: theme.px2rem(15),
      },
    },
    fallbackMessage: {
      fontWeight: 500,
      color: theme.colors.onevisionBlack80,
      textAlign: 'center',
    },
    fallbackMessageSecondary: {
      color: theme.colors.onevisionBlack50,
      textAlign: 'center',
    },
  };
});
