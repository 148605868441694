import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      header: {
        fontSize: theme.px2rem(24),
        fontWeight: 600,
        textAlign: 'center',
        color: theme.colors.black,
      },
      headerText: {
        fontSize: theme.px2rem(24),
        fontWeight: 400,
        textAlign: 'center',
        color: theme.colors.black,
        marginTop: theme.px2rem(50),
      },
      intermediateStep: {
        margin: theme.px2rem(50),
      },
      chooseButtonsWrapper: {
        marginTop: theme.px2rem(50),
      },
      choiceButton: {
        width: theme.px2rem(291),
        height: theme.px2rem(75),
        margin: theme.px2rem(10),
      },
      helpButton: {
        backgroundColor: theme.colors.primary,
        color: '#fff',
        '&:hover': {
          backgroundColor: '#C16C0B',
        },
      },
      optionsButton: {
        border: `1px solid ${theme.colors.primary}`,
        color: theme.colors.primary,
      },
    };
  },
  { name: 'af-membership-page' },
);
