import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Box, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useStyles } from './af-feature-line.styles';

export interface FeatureLineProps {
  text: string;
  included: boolean;
  tooltip?: string;
}

export const AfFeatureLineView: React.FC<FeatureLineProps> = ({
  text,
  included,
  tooltip,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.featureLine}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <div>
          <span
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(text, {
                allowedTags: ['br', 'strike'],
              }),
            }}
          />
          {!!tooltip && (
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
              title={
                <div
                  className={classes.textDiv}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(tooltip, {
                      allowedTags: ['br', 'strike'],
                    }),
                  }}
                />
              }
              placement="right"
              arrow
            >
              <InfoIcon
                type="outlined"
                className={classes.additionalInfoIcon}
              />
            </Tooltip>
          )}
        </div>
      </Box>
      {included ? (
        <CheckCircleIcon
          data-testid="checked-icon-feature"
          className={classes.optionIsChecked}
        />
      ) : (
        <CancelIcon
          data-testid="unchecked-icon-feature"
          className={classes.optionIsUnchecked}
        />
      )}
    </Box>
  );
};
