import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      optionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        marginBottom: theme.px2rem(10),
        '&:last-of-type': {
          margin: 0,
        },
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginBottom: theme.px2rem(13),
        },
      },
      accordion: {
        zIndex: 2,
        width: '85%',
        boxShadow: '1px 1px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px !important',
        marginBottom: '0 !important',
      },
      summary: {
        padding: 0,
      },
      summaryContent: {
        margin: 0,
      },
      summaryExpanded: {
        marginBottom: '0 !important',
        marginTop: '0 !important',
      },
      optionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.px2rem(20),
        borderRadius: '20px',
        color: theme.colors.black,
        backgroundColor: '#fff',
        width: '100%',
        height: theme.px2rem(106),
      },
      optionButtonActive: {
        backgroundColor: theme.colors.secondary,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.colors.secondary,
        },
      },
      optionButtonLeft: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '45%',
        fontSize: theme.px2rem(16),
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'flex-start',
          fontSize: theme.px2rem(18),
        },
        '& svg': {
          [theme.breakpoints.down('md')]: {
            marginRight: theme.px2rem(10),
            marginLeft: theme.px2rem(3),
          },
        },
      },
      optionButtonLeftContact: {
        width: '70%',
      },
      optionButtonPrice: {
        display: 'flex',
        flexDirection: 'row',
        '& div:first-of-type': {
          fontSize: theme.px2rem(20),
          fontWeight: 700,
          [theme.breakpoints.down('md')]: {
            fontWeight: 500,
          },
        },
        '& div:last-of-type': {
          fontSize: theme.px2rem(14),
          display: 'flex',
          alignItems: 'flex-end',
          textTransform: 'lowercase',
          paddingBottom: theme.px2rem(4),
        },
      },
      optionButtonPriceContact: {
        flexDirection: 'column',
        '& div:first-of-type': {
          fontSize: theme.px2rem(14),
        },
      },
      recommendationLabel: {
        zIndex: 1,
        position: 'absolute',
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        backgroundColor: '#32CA94',
        boxShadow: '1px 1px 12px rgba(0, 0, 0, 0.2)',
        color: '#FFFFFF',
        height: theme.px2rem(106),
        width: '50%',
        borderRadius: '20px',
      },
      recommend: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '22%',
        fontSize: theme.px2rem(11),
        marginLeft: 'auto',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          width: '15%',
          '& > svg': {
            display: 'none',
          },
          '& > div': {
            transform: 'rotate(90deg)',
            fontSize: theme.px2rem(10),
            fontWeight: 700,
          },
        },
      },
    };
  },
  { name: 'af-membership-mob-option-button' },
);
