import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  footerIsShown: boolean;
  showChat: boolean;
}

export const footerSlice = createSlice({
  name: 'footer',
  initialState: { footerIsShown: false, showChat: false } as State,
  reducers: {
    updateFooter: (state, action: PayloadAction<Partial<State>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});
