import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      '@global': {
        html: {
          boxSizing: 'border-box',

          fontSize: 14,
          [theme.breakpoints.down('sm')]: {
            fontSize: 12,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 14,
          },
        },
        '#root': {
          minHeight: '100vh',
        },
      },
      root: {
        zIndex: 1,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100%',
        position: 'relative',
        height: 'auto',
      },
      rootTabletWholePage: {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
      rootJustify: {
        justifyContent: 'space-between',
      },
      bgImage: {
        backgroundColor: '#fff',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: -1,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      bgImageHidden: {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      contentWrapper: {
        padding: `${theme.px2rem(50)} 0`,
        [theme.breakpoints.down('md')]: {
          padding: 0,
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          height: '100%',
        },
      },
      contentWrapperMargin: {
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '100%',
        },
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '20px',
        position: 'relative',
        flexDirection: 'column',
        maxWidth: theme.px2rem(1200),
        animation: '$fadeIn 1.4s ease-in-out',
        backgroundColor: '#fff',
        padding: theme.px2rem(25),
        margin: 'auto',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 0,
          padding: `${theme.px2rem(50)} ${theme.px2rem(25)}`,
        },
      },
      tabletView: {
        [theme.breakpoints.down('md')]: {
          borderRadius: 0,
          height: '100%',
          padding: `${theme.px2rem(20)} ${theme.px2rem(25)}`,
        },
      },
      '@keyframes fadeIn': {
        '0%': {
          opacity: 0,
          transform: 'scale(1.05)',
        },
        '100%': {
          opacity: 1,
          transform: 'unset',
        },
      },
      logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: theme.px2rem(250),
      },
      logoIcon: {
        marginBottom: theme.px2rem(15),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
    };
  },
  { name: 'af-root' },
);
