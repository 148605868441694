import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      header: {
        fontSize: theme.px2rem(36),
        fontWeight: 600,
        textAlign: 'center',
        color: theme.colors.black,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(24),
        },
      },
      headerText: {
        fontSize: theme.px2rem(16),
        fontWeight: 400,
        textAlign: 'center',
        color: theme.colors.black,
        marginTop: theme.px2rem(15),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(14),
        },
      },
      intermediateStep: {
        margin: `${theme.px2rem(30)} ${theme.px2rem(20)} ${theme.px2rem(
          15,
        )} ${theme.px2rem(20)}`,
        [theme.breakpoints.down('md')]: {
          margin: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          margin: `${theme.px2rem(30)} 0`,
        },
      },
      content: {
        marginTop: theme.px2rem(20),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: theme.px2rem(25),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
      switchWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.px2rem(15),
        color: theme.colors.black,
        alignItems: 'center',
      },
      switchTrack: {
        backgroundColor: `${theme.colors.secondary} !important`,
        opacity: `1 !important`,
      },
      switchThumb: {
        color: `#fafafa !important`,
      },
      featuresColumn: {
        backgroundColor: '#C4C4C433',
        borderRadius: '20px',
        padding: theme.px2rem(15),
        [theme.breakpoints.up('md')]: {
          minWidth: theme.px2rem(450),
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
        '& > p:first-of-type': {
          color: '#828282',
          fontSize: theme.px2rem(16),
        },
      },
      featureTitle: {
        marginBottom: theme.px2rem(15),
      },
      blueText: {
        color: theme.colors.primary,
        fontWeight: 700,
      },
      featureText: {
        margin: `0 ${theme.px2rem(3)} 0 0`,
      },
      margin0: {
        margin: 0,
      },
      featureTextStrike: {
        textDecoration: 'line-through',
      },
      additionalInfoIcon: {
        marginLeft: theme.px2rem(5),
        color: '#BDBDBD',
      },
      optionsMob: {
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      optionsColumn: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      divider: {
        marginTop: theme.px2rem(20),
        marginBottom: theme.px2rem(20),
        display: 'flex',
        width: '90%',
        borderTop: '1px solid rgba(0, 0, 0, 0.3)',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginTop: theme.px2rem(30),
          width: '100%',
        },
      },
      footer: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      footerLeft: {
        display: 'flex',
        flexDirection: 'column',
        '& > p:first-of-type': {
          color: theme.colors.black,
          fontSize: theme.px2rem(14),
          fontWeight: 500,
          [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
            textAlign: 'center',
          },
        },
        '& > p:nth-child(2)': {
          color: theme.colors.black,
          fontSize: theme.px2rem(14),
          [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
            textAlign: 'center',
          },
        },
      },
      girlIconWrapper: {
        marginTop: theme.px2rem(15),
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          justifyContent: 'center',
        },
      },
      girlIcon: {
        width: theme.px2rem(60),
        height: theme.px2rem(60),
        borderRadius: '50%',
      },
      chatButton: {
        color: theme.colors.secondary,
        marginLeft: theme.px2rem(15),
        height: '50%',
        alignSelf: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          textDecoration: 'underline',
        },
      },
      wrapNav: {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      navigation: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: theme.px2rem(25),
        },
      },
      navText: {
        color: theme.colors.onevisionBlack50,
        fontSize: theme.px2rem(12),
        marginTop: theme.px2rem(12),
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      nextStepButton: {
        marginTop: theme.px2rem(15),
        backgroundColor: theme.colors.primary,
        color: '#fff',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '60%',
          height: theme.px2rem(50),
          borderRadius: '10px',
        },
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness95,
        },
      },
      lastStepButton: {
        marginTop: theme.px2rem(15),
        color: theme.colors.secondary,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '50%',
          textDecoration: 'underline',
          marginTop: theme.px2rem(12),
        },
        '&:hover': {
          [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
            textDecoration: 'underline',
          },
        },
      },
      faqMobile: {
        display: 'none',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'flex',
        },
      },
      faq: {
        backgroundColor: 'transparent',
        padding: `${theme.px2rem(30)} 0`,
      },
      spinner: {
        marginLeft: theme.px2rem(5),
        color: '#fff',
      },
      graySpinner: {
        color: 'rgba(0, 0, 0, 0.26)',
      },
      footerReversed: {
        flexDirection: 'row-reverse',
      },
    };
  },
  { name: 'af-membership-options' },
);
