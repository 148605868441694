import { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { CheckoutFormikData } from './af-checkout-step.view';
import {
  ActivationFlowData,
  ProductOffered,
  SubmitPaymentParams,
} from 'core/types';
import { pricePointIdHandler } from 'core/utils';
import { useAfSelector } from 'core/redux';
import { actions } from 'core/redux';
import { API } from 'core/api';

const getStripeBillingAddress = (
  data: ActivationFlowData | CheckoutFormikData,
) => ({
  city: data.city,
  state: data.state,
  postal_code: String(data.zip),
  line1: data.address1,
  line2: data.address2 || '',
});
const getApiBillingAddress = (
  data: ActivationFlowData | CheckoutFormikData,
) => ({
  city: data.city,
  state: data.state,
  zip: String(data.zip),
  address1: data.address1,
  address2: data.address2 || '',
});

export const usePaymentSubmit = (
  stripe: Stripe | null,
  stripeElement: MutableRefObject<StripeCardElement | null>,
  setErrorCC: (message: string) => void,
  setLoading: (state: boolean) => void,
  couponId: string,
) => {
  const dispatch = useDispatch();

  const afData = useAfSelector((state) => state.afData);
  const { plan, yearly, upcomingInvoice } = useAfSelector(
    (state) => state.membershipSteps,
  );

  const getSetupIntent = async (customerId?: string) => {
    const params: { customerId?: string } = {};
    if (customerId) {
      params.customerId = customerId;
    }
    const {
      data: {
        data: { clientSecret },
      },
    } = await API.setupIntent(params);
    return clientSecret;
  };

  const createPaymentMethod = (formikData: CheckoutFormikData) => {
    if (stripe && stripeElement.current) {
      return stripe.createPaymentMethod({
        card: stripeElement.current,
        type: 'card',
        billing_details: {
          email: formikData.email,
          name: formikData.fullName,
          address: getStripeBillingAddress(
            formikData.sameAddress ? afData : formikData,
          ),
        },
      });
    }
  };

  const confirmCardSetup = async (
    paymentMethodId: string,
    clientSecret: string,
  ) => {
    if (stripe) {
      const response = await stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethodId,
      });
      const dataResponse = response as { error?: { message: string } };
      if (dataResponse?.error) {
        dispatch(
          actions.updateSnackbar({
            text: dataResponse.error.message,
            type: 'error',
          }),
        );
        setErrorCC(dataResponse.error.message);
        setLoading(false);
        throw new Error('setupIntent error');
      }
    }
  };

  const submitPay = (
    paymentMethodId: string,
    formikData: CheckoutFormikData,
    update: boolean,
  ) => {
    const chosenPlan = plan as ProductOffered;
    const submitPayload: SubmitPaymentParams = {
      signup: true,
      update: update,
      details: {
        subscriptionId: afData.subscriptionId as string,
        paymentMethodId,
        cardHolderName: formikData.fullName,
        billingEmail: formikData.email,
        billingAddress: getApiBillingAddress(
          formikData.sameAddress ? afData : formikData,
        ),
        productId: chosenPlan.productId,
        pricePointId: pricePointIdHandler(afData, chosenPlan, yearly),
        coupon: couponId,
      },
    };
    if (upcomingInvoice) {
      submitPayload.details.prorationDate =
        upcomingInvoice.subscriptionProrationDate;
    }
    return API.submitPayment(submitPayload);
  };

  return {
    getSetupIntent,
    createPaymentMethod,
    confirmCardSetup,
    submitPay,
  };
};
