import { Typography, Box } from '@mui/material';
import React from 'react';
import { BackgroundInit } from '../../assets';
import { thumb } from '../../core/constants';
import { AfLazyLoadImage } from '../shared-components';
import { csn } from '@one-vision/utils';
import { Af404ErrorIcon } from './af-404-error-icon';
import { useStyles } from './af-404-page.styles';

export const Af404Page: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <AfLazyLoadImage
        className={csn(classes.bgImage)}
        src={BackgroundInit}
        thumbnail={thumb}
      />
      <Box className={classes.wrapper}>
        <Af404ErrorIcon />
        <Box className={classes.textWrapper}>
          <Typography
            variant="h4"
            className={classes.errorMessage}
            role="error-message"
          >
            Page Not Found
          </Typography>
          <Typography className={classes.errorMessageSecondary}>
            Please contact us for more information
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
