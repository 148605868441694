import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        padding: `${theme.px2rem(50)} 0 0 0`,
      },
      header: {
        fontSize: theme.px2rem(32),
        fontWeight: 600,
        textAlign: 'center',
        color: theme.colors.black,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(24),
        },
      },
      headerCaption: {
        fontSize: theme.px2rem(14),
        fontWeight: 400,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.6)',
        marginTop: theme.px2rem(10),
      },
      headerQuestion: {
        fontSize: theme.px2rem(26),
        fontWeight: 500,
        textAlign: 'center',
        color: theme.colors.black,
        marginTop: theme.px2rem(30),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(20),
          marginTop: theme.px2rem(20),
        },
      },
      buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: `${theme.px2rem(30)} ${theme.px2rem(20)}`,
        [theme.breakpoints.down('lg')]: {
          margin: `${theme.px2rem(30)} 0`,
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          flexDirection: 'column',
        },
      },
      button: {
        padding: `${theme.px2rem(20)} ${theme.px2rem(30)}`,
        margin: theme.px2rem(15),
        width: theme.px2rem(250),
        minHeight: theme.px2rem(100),
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        color: '#fff',
        backgroundColor: theme.colors.primary,
        filter: theme.brightness.brightness135,
        boxShadow:
          '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness100,
        },
        [theme.breakpoints.down('lg')]: {
          width: theme.px2rem(200),
          minHeight: theme.px2rem(120),
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          margin: `0 0 ${theme.px2rem(25)} 0`,
          width: '100%',
        },
      },
      backButton: {
        color: theme.colors.secondary,
      },
      divider: {
        display: 'none',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginTop: theme.px2rem(20),
          display: 'flex',
          width: '100%',
          selfAlign: 'center',
          borderTop: '1px solid rgba(0, 0, 0, 0.4)',
        },
      },
      showButton: {
        marginBottom: theme.px2rem(30),
        padding: `${theme.px2rem(6)} ${theme.px2rem(25)}`,
        color: '#fff',
        backgroundColor: theme.colors.primary,
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness95,
        },
      },
      stepHidden: {
        display: 'none',
      },
      answerIsChosen: {
        filter: theme.brightness.brightness70,
      },
    };
  },
  { name: 'af-help-step' },
);
