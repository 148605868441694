import React, { useState } from 'react';
import { csn } from '@one-vision/utils';

import { useStyles } from './af-azy-load-image.styles';

export interface LazyLoadImageProps {
  thumbnail: string;
  className?: string;
  alt?: string;
  src: string;
}

export const AfLazyLoadImageView: React.FC<LazyLoadImageProps> = ({
  alt,
  src,
  thumbnail,
  className,
}) => {
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);

  return (
    <div className={csn(className, classes.root)}>
      <div
        className={classes.thumb}
        style={{
          backgroundImage: thumbnail,
        }}
      ></div>
      <img
        className={csn(classes.img, { [classes.imgHidden]: !loaded })}
        alt={alt}
        src={src}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};
