import React from 'react';

interface Props {
  fallback: React.ReactNode;
  children: React.ReactNode;
}

interface AfErrorBoundaryState {
  errorFree: boolean;
}

export class AfErrorBoundary extends React.Component<
  Props,
  AfErrorBoundaryState
> {
  state: AfErrorBoundaryState = {
    errorFree: true,
  };

  static getDerivedStateFromError() {
    return {
      errorFree: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (
      process.env.NODE_ENV !== 'production' &&
      process.env.NODE_ENV !== 'test'
    ) {
      console.log('error :>> ', error);
      console.log('errorInfo :>> ', errorInfo);
    }
  }

  render() {
    return this.state.errorFree
      ? this.props.children
      : this.props.fallback;
  }
}
