import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useStyles } from './af-tos-skeleton.styles';

export const AfTosSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Skeleton variant="text" className={classes.line} width="50%" />
        <Skeleton variant="text" className={classes.line} width="80%" />
        <Skeleton variant="text" className={classes.line} width="80%" />
      </Box>
      <Box className={classes.contentBlock}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Skeleton className={classes.line} width="40%" />
          <Skeleton className={classes.line} width="10%" />
        </Box>
        <Box display="flex" flexDirection="row">
          <Skeleton variant="circular" className={classes.circle} />
          <Box width="75%">
            <Skeleton className={classes.line} width="100%" />
            <Skeleton className={classes.line} width="100%" />
            <Skeleton className={classes.line} width="100%" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.contentBlock}>
        <Box>
          <Skeleton className={classes.line} width="40%" />
        </Box>
        <Box display="flex" flexDirection="row">
          <Skeleton variant="circular" className={classes.circle} />
          <Box width="75%">
            <Skeleton className={classes.line} width="100%" />
            <Skeleton className={classes.line} width="100%" />
            <Skeleton className={classes.line} width="100%" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.bottom}>
        <Skeleton className={classes.line} width="30%" />
        <Skeleton className={classes.line} width="40%" />
      </Box>
    </Box>
  );
};
