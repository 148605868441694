import React from 'react';
import { MapLike } from 'typescript';
import { Typography } from '@mui/material';
import { useStyles } from './af-faq-text.styles';
import { csn } from '@one-vision/utils';

export const AfFaqBody: React.FC<{ classesCustom?: MapLike<string> }> = ({
  classesCustom,
}) => {
  const classes = { ...useStyles(), ...classesCustom };

  return (
    <div className={classes.faq}>
      <Typography variant="h3" className={classes.faqHeader}>
        FAQs
      </Typography>
      <div className={classes.textChunk}>
        <Typography className={classes.question}>
          Why do you offer support memberships?
        </Typography>
        <Typography className={classes.answer}>
          Many companies will tell you they take service seriously. But
          when you need help most, you&#39;re stuck hoping they answer the
          phone. We, on the other hand, put our service policies in black
          and white and guarantee we&#39;ll be there for you, 24/7/365.
        </Typography>
      </div>
      <div className={classes.textChunk}>
        <Typography className={csn(classes.answerBold, classes.answer)}>
          We&#39;re different. Our membership program is built on a two
          simple principles:
        </Typography>
        <Typography className={classes.answer}>
          1. You should have access to that service and support the moment
          you need it. Guaranteed.
        </Typography>
        <Typography className={classes.answer}>
          2. You should be able to pick the level of service and support
          that is right for your needs
        </Typography>
      </div>
      <div className={classes.textChunk}>
        <Typography className={classes.question}>
          What happens if I don&#39;t want a membership?
        </Typography>
        <Typography className={classes.answer}>
          No problem. All new properties include a minimum of 30 days of
          complimentary premium support. After that, we&#39;re always happy
          to schedule an on-site appointment based on availability and
          billed at our standard hourly rates. Memberships are only
          required if you want access to benefits like remote tech support,
          prioritized scheduling, discounted labor rates, and more.
        </Typography>
      </div>
      <div className={classes.textChunk}>
        <Typography className={classes.question}>
          Is there any commitment?
        </Typography>
        <Typography className={classes.answer}>
          We offer monthly plans with no commitment; you can cancel
          anytime. Cancellations will process at the end of your active
          term. We also offer annual plans for the convenience of a
          one-time payment. Please note, these plans are non-refundable.
        </Typography>
      </div>
      <div className={classes.textChunk}>
        <Typography className={classes.question}>
          What is your refund/cancelation policy?
        </Typography>
        <Typography className={classes.answer}>
          You can cancel anytime, effective at the end of your billing
          period. There are no refunds or credits for partial months or
          years.
        </Typography>
      </div>
    </div>
  );
};
