import React, { useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { ChooseWay } from './af-intermediate-steps/af-choose-way';
import { HelpStep } from './af-intermediate-steps/af-help-step';
import { ChooseMembership } from './af-intermediate-steps/af-membership-plans';
import { Answers } from '../../../core/redux/slices/answers.redux';
import { MembershipSteps } from '../../../core/redux/slices/membership-step.redux';
import { actions, useAfSelector } from '../../../core/redux';
import { Csm } from '../../../core/types';
import { checkMembership } from 'core/utils';
import { useStyles } from './af-membership-step.styles';
interface Props {
  nextStep: () => void;
  lastStep: () => void;
}

export const AfMembershipStepView: React.FC<Props> = ({
  nextStep,
  lastStep,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { question1, question2, question3 } = useAfSelector(
    (state) => state.answers,
  );

  const { internalStep } = useAfSelector((state) => state.membershipSteps);

  const activationFlowData = useAfSelector((state) => state.afData);

  useEffect(() => {
    if (
      checkMembership(activationFlowData.desiredMembership as Csm) ||
      !activationFlowData.recommendationEngine
    ) {
      setInternalStepHandler(MembershipSteps.Options);
    }
  }, [activationFlowData]);

  const createButtonObject = (
    answer: Answers,
    name: string,
    onAnswer: () => void,
  ) => ({
    answer,
    name,
    onClick: () => onAnswer(),
  });
  const buttonListHelp1 = [
    createButtonObject(
      Answers.YesOrAlways,
      'Yes',
      useCallback(() => {
        dispatch(
          actions.updateAnswers({ question1: Answers.YesOrAlways }),
        );
        dispatch(
          actions.updateMembershipSteps({
            internalStep: MembershipSteps.HelpMe2,
          }),
        );
      }, [question1, internalStep]),
    ),
    createButtonObject(
      Answers.NoOrNever,
      'No',
      useCallback(() => {
        dispatch(actions.updateAnswers({ question1: Answers.NoOrNever }));
        dispatch(
          actions.updateMembershipSteps({
            internalStep: MembershipSteps.HelpMe2,
          }),
        );
      }, [question1, internalStep]),
    ),
  ];
  const buttonListHelp2 = [
    createButtonObject(
      Answers.YesOrAlways,
      'Yes',
      useCallback(() => {
        dispatch(
          actions.updateAnswers({ question2: Answers.YesOrAlways }),
        );
        dispatch(
          actions.updateMembershipSteps({
            internalStep: MembershipSteps.HelpMe3,
          }),
        );
      }, [question2, internalStep]),
    ),
    createButtonObject(
      Answers.NoOrNever,
      'No',
      useCallback(() => {
        dispatch(actions.updateAnswers({ question2: Answers.NoOrNever }));
        dispatch(
          actions.updateMembershipSteps({
            internalStep: MembershipSteps.HelpMe3,
          }),
        );
      }, [question2, internalStep]),
    ),
  ];
  const buttonListHelp3 = [
    createButtonObject(
      Answers.YesOrAlways,
      'Always',
      useCallback(() => {
        dispatch(
          actions.updateAnswers({ question3: Answers.YesOrAlways }),
        );
      }, [question3]),
    ),
    createButtonObject(
      Answers.Sometimes,
      'Sometimes',
      useCallback(() => {
        dispatch(actions.updateAnswers({ question3: Answers.Sometimes }));
      }, [question3]),
    ),
    createButtonObject(
      Answers.NoOrNever,
      'Never',
      useCallback(() => {
        dispatch(actions.updateAnswers({ question3: Answers.NoOrNever }));
      }, [question3]),
    ),
  ];

  const setInternalStepHandler = useCallback(
    (step: MembershipSteps) => {
      dispatch(actions.updateMembershipSteps({ internalStep: step }));
    },
    [dispatch, internalStep],
  );

  const content = useMemo(() => {
    switch (internalStep) {
      case MembershipSteps.ChooseWay:
        return <ChooseWay setStep={setInternalStepHandler} />;
      case MembershipSteps.HelpMe1:
        return (
          <HelpStep
            question="Do you want remote support?"
            currentAnswer={question1}
            listButtons={buttonListHelp1}
            currentStep={internalStep}
            setStep={setInternalStepHandler}
          />
        );
      case MembershipSteps.HelpMe2:
        return (
          <HelpStep
            question="Do you want monitoring of your system?"
            currentAnswer={question2}
            listButtons={buttonListHelp2}
            currentStep={internalStep}
            setStep={setInternalStepHandler}
          />
        );
      case MembershipSteps.HelpMe3:
        return (
          <HelpStep
            question="Do you want priority scheduling and weekend availability?"
            currentAnswer={question3}
            listButtons={buttonListHelp3}
            currentStep={internalStep}
            setStep={setInternalStepHandler}
          />
        );
      case MembershipSteps.Options:
        return (
          <ChooseMembership
            question2={question2}
            question3={question3}
            nextStep={nextStep}
            lastStep={lastStep}
            data={activationFlowData}
          />
        );
      default:
        break;
    }
  }, [question1, question2, question3, internalStep]);

  return <Box className={classes.root}>{content}</Box>;
};
