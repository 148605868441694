import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles((theme: ExtTheme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      minWidth: theme.px2rem(590),
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.px2rem(320),
      },
    },
    stepBlock: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    stepCircle: {
      height: theme.px2rem(24),
      width: theme.px2rem(24),
    },
    stepName: {
      borderRadius: '2px',
      height: theme.px2rem(24),
      width: '80%',
    },
    divider: {
      height: theme.px2rem(2),
      position: 'absolute',
      top: theme.px2rem(12),
      flex: '1 1 auto',
      left: 'calc(-50% + 20px)',
      right: 'calc(+50% + 20px)',
      width: '72%',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
  };
});
