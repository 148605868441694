import { useAfSelector } from 'core/redux';
import { ProductOffered } from 'core/types';
import React, { useCallback } from 'react';
import { AfFeatureLine } from './af-feature-line';
import { Box } from '@mui/material';

export const AFMembershipFeatureList = ({
  productOffer,
}: {
  productOffer: ProductOffered;
}) => {
  const activationFlowData = useAfSelector((state) => state.afData);

  const renderMembershipFeatureList = useCallback(() => {
    if (!activationFlowData || !activationFlowData.membershipFeatures) {
      return null;
    }

    return activationFlowData.membershipFeatures.map((feature) => {
      const activeMembershipFeature =
        feature.activeMembershipFeatures.find(
          (activeFeature) =>
            activeFeature.productNameId === productOffer?.productId,
        );

      const content =
        activeMembershipFeature?.overrideContent || feature.defaultContent;

      return (
        <AfFeatureLine
          key={feature.membershipFeatureId}
          text={content}
          included={!!activeMembershipFeature}
          tooltip={
            activeMembershipFeature?.overrideTooltip ||
            feature.defaultTooltip ||
            ''
          }
        />
      );
    });
  }, [productOffer, activationFlowData]);

  return <Box>{renderMembershipFeatureList()}</Box>;
};
