import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Chip, InputBase } from '@mui/material';
import { csn } from '@one-vision/utils';
import { actions, useAfSelector } from '../../../../core/redux';
import { API } from 'core/api';
import {
  ActivationFlowData,
  ProductOffered,
  ErrorResponse,
} from 'core/types';
import {
  priceHandler,
  sumDecoration,
  getStructuredError,
  pricePointIdHandler,
} from 'core/utils';
import { useStyles } from './af-coupon-input.styles';

export const AfCouponInputView: React.FC<{
  data: ActivationFlowData;
  amount: number;
  couponSet: (value: number) => void;
  setCouponId: (name: string) => void;
  loading: boolean;
  setIsLoading: (status: boolean) => void;
}> = ({
  data: afData,
  amount,
  couponSet,
  setCouponId,
  loading,
  setIsLoading,
}) => {
  const classes = useStyles();

  const [couponShow, setCouponShow] = useState(false);
  const [coupon, setCoupon] = useState('');

  const dispatch = useDispatch();

  const plan = useAfSelector(
    (state) => state.membershipSteps.plan as ProductOffered,
  );
  const yearly = useAfSelector((state) => state.membershipSteps.yearly);

  const couponHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCoupon(e.target.value);
    },
    [setCoupon],
  );

  const couponShowHandler = useCallback(() => {
    setCouponShow(true);
  }, [setCouponShow]);

  const couponApplyHandler = useCallback(async () => {
    setIsLoading(true);
    if (afData.activeSubscription) {
      await couponApplyWithSub(coupon);
      return;
    }

    await couponApply();
  }, [coupon, plan, yearly, setCouponId, afData, setIsLoading]);

  const couponApply = async () => {
    try {
      const response = await API.submitCoupon({
        couponId: coupon.toUpperCase(),
      });
      const { data } = response.data;
      console.log(data);
      console.log(plan);
      if (data.valid) {
        if (
          !data?.applies_to ||
          data?.applies_to.products.includes(plan.stripeProductId)
        ) {
          dispatch(
            actions.updateSnackbar({
              text: 'Coupon Applied',
              type: 'success',
            }),
          );
          setCouponShow(false);
          setCouponId(data.id);

          if (data.amount_off) {
            const amountWithoutCents = data.amount_off / 100;
            const checkedAmount =
              amountWithoutCents > priceHandler(afData, plan, yearly)
                ? priceHandler(afData, plan, yearly)
                : amountWithoutCents;
            couponSet(checkedAmount);
            return;
          }
          if (data.percent_off) {
            couponSet(
              (priceHandler(afData, plan, yearly) * data.percent_off) /
                100,
            );
          }
          return;
        }
        if (
          data?.applies_to &&
          !data?.applies_to.products.includes(plan.stripeProductId)
        ) {
          throw getStructuredError(
            'Coupon not available for this product',
          );
        }
      }

      throw getStructuredError('Invalid coupon');
    } catch (err) {
      const error = err as ErrorResponse;
      if (error.response?.data?.errors?.length) {
        dispatch(
          actions.updateSnackbar({
            text: error.response.data.errors[0].detail,
            type: 'error',
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const couponApplyWithSub = async (currentCoupon?: string) => {
    const params: {
      ['product-id']: number;
      ['price-point-id']: string;
      coupon?: string;
    } = {
      ['product-id']: plan.productId,
      ['price-point-id']: pricePointIdHandler(afData, plan, yearly),
    };
    if (currentCoupon) {
      params.coupon = currentCoupon.toUpperCase();
    }
    try {
      const {
        data: { data: upcomingInvoice },
      } = await API.getUpcomingInvoice(params);
      dispatch(
        actions.updateMembershipSteps({
          upcomingInvoice,
        }),
      );

      const couponDetails = await API.submitCoupon({
        couponId: coupon.toUpperCase(),
      });

      setCouponId(couponDetails.data.data.id);
      setCouponShow(false);
    } catch {
      dispatch(
        actions.updateSnackbar({
          text: 'Can`t apply coupon',
          type: 'error',
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const couponDeleteHandler = useCallback(async () => {
    if (afData.activeSubscription) {
      setIsLoading(true);
      try {
        await couponApplyWithSub();
      } finally {
        setIsLoading(false);
      }
    }
    setCoupon('');
    dispatch(
      actions.updateSnackbar({
        text: 'Coupon Removed',
        type: 'info',
      }),
    );
    couponSet(0);
  }, [setCoupon, couponSet, setIsLoading]);

  return (
    <Box
      className={classes.couponLine}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {!couponShow && !coupon && (
        <Button className={classes.coupon} onClick={couponShowHandler}>
          Add coupon code
        </Button>
      )}
      {couponShow && (
        <Box
          className={csn(classes.couponInputWrapper, [
            classes.couponInputWithText,
            coupon,
          ])}
        >
          <InputBase
            placeholder="Add coupon code"
            value={coupon}
            onChange={couponHandler}
            autoFocus
            className={classes.couponInput}
            inputProps={{ 'data-testid': 'coupon-input' }}
          />
          <Button
            disabled={loading}
            className={classes.applyCoupon}
            onClick={couponApplyHandler}
          >
            Apply
          </Button>
        </Box>
      )}
      {!couponShow && coupon && (
        <Chip
          className={classes.chip}
          label={coupon}
          onDelete={couponDeleteHandler}
        />
      )}
      {!couponShow && coupon && !afData.activeSubscription && (
        <Box className={classes.couponSum}>{`-$${sumDecoration(
          amount,
        )}`}</Box>
      )}
    </Box>
  );
};
