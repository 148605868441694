import React, { useCallback, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { Phone, Email } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { tryToFormatPhoneNumber, csn } from '@one-vision/utils';
import { AfConfirmationIcon } from '../../shared-components/af-icons';
import { useStyles } from './af-confirmation-step.styles';
import { useAfSelector, actions } from 'core/redux';
import { API } from 'core/api';
import { checkMembership } from 'core/utils';
import { Csm, AF_ACTION, AfActionsPayload } from 'core/types';

interface Props {
  membershipStep: () => void;
}

export const AfConfirmationStepView: React.FC<Props> = ({
  membershipStep,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const afData = useAfSelector((state) => state.afData);

  const isDeclined =
    afData.desiredMembership === Csm['Declined Membership'];
  const isServiceClient =
    (checkMembership(afData.desiredMembership as Csm) &&
      afData.creditCardProvided &&
      afData.tosAccepted) ||
    (checkMembership(afData.serviceLevel as Csm) &&
      afData.activeSubscription);
  const noActiveSubButPaid =
    !afData.activeSubscription &&
    checkMembership(afData.desiredMembership as Csm) &&
    afData.creditCardProvided &&
    afData.tosAccepted;

  const upgradeHandler = useCallback(async () => {
    setLoading(true);
    const payload: AfActionsPayload = {
      action: AF_ACTION.UPGRADE_DECLINED_MEMBERSHIP,
    };
    try {
      if (afData.activeSubscription) {
        const {
          data: { data: subscriptionDetails },
        } = await API.getStripeSubscriptionDetails({
          addressId: afData.addressId,
        });

        dispatch(
          actions.updateMembershipSteps({
            subscriptionDetails,
            yearly: subscriptionDetails.billingFrequency === '1-year',
          }),
        );
      } else {
        await API.submitActivationFlowAction(payload);
      }
    } catch {
      dispatch(
        actions.updateSnackbar({
          text: 'Error',
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
      membershipStep();
    }
  }, [membershipStep, setLoading]);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center">
        <AfConfirmationIcon classes={{ root: classes.mobConfirmIcon }} />
      </Box>
      <Typography className={classes.header}>
        {isDeclined
          ? `Hi ${afData.firstName}!`
          : `You’re all set, ${afData.firstName}!`}
      </Typography>
      {!isDeclined && !isServiceClient && (
        <Typography className={classes.paragraph}>
          We’ve sent a confirmation email to <b>{afData.email}</b>
        </Typography>
      )}
      {(isServiceClient || noActiveSubButPaid) && (
        <Typography className={classes.paragraph}>
          {`Thank you for being ${
            afData.serviceLevel === Csm['Essentials Plus'] ? 'an' : 'a'
          } ${afData.serviceLevel} member.`}
        </Typography>
      )}
      <Typography className={classes.paragraph}>
        Take a moment to add our Support information to your contacts so
        you can easily get in touch.
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.cardWrapper}
      >
        <Box className={classes.card}>
          <Box className={classes.logoWrapper}>
            <img src={afData.logo} className={classes.logoIcon} />
          </Box>
          <Typography className={classes.serviceTeamCaption}>
            Contact our Service Team:
          </Typography>
          <Box display="flex" className={classes.cardLine}>
            <Phone className={classes.cardLineIcon} />
            {afData.serviceLevel === Csm.Signature
              ? tryToFormatPhoneNumber(afData.signaturePhone)
              : tryToFormatPhoneNumber(afData.supportPhone)}
          </Box>
          <Box display="flex" className={classes.cardLine}>
            <Email className={classes.cardLineIcon} />
            {afData.serviceLevel === Csm.Signature
              ? afData.signatureEmail
              : afData.supportEmail}
          </Box>
        </Box>
      </Box>
      <Typography className={classes.bottomText}>
        If you are ever interested in updating your support experience
        click below
      </Typography>
      <Button
        disabled={loading}
        className={classes.upgradeButton}
        onClick={upgradeHandler}
      >
        View membership options
        {loading ? (
          <CircularProgress
            classes={{
              root: csn(classes.spinner, [classes.graySpinner, loading]),
            }}
            size="1rem"
          />
        ) : null}
      </Button>
    </Box>
  );
};
