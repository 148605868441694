import {
  createTheme,
  ThemeProvider,
  Theme,
  ThemeOptions,
  StyledEngineProvider,
} from '@mui/material';
import * as React from 'react';

export const ovColors = {};

export const themeCustomParams = {
  /*
    @base should be taken from `htmlFontSize` if treated as root rem font-size
  */
  px2rem: (px: number, htmlFontSize = 14) => {
    const rem = (px / htmlFontSize).toFixed(2);

    return `${rem}rem`;
  },
  zp: {},
  af: {
    breakpoints: {
      sm: 768,
    },
  },
  brightness: {
    brightness70: 'brightness(70%)',
    brightness80: 'brightness(80%)',
    brightness90: 'brightness(90%)',
    brightness95: 'brightness(95%)',
    brightness100: 'brightness(100%)',
    brightness135: 'brightness(135%)',
  },
  colors: {
    black: '#000000',
    onevisionBlack: '#1F2124',
    onevisionBlack20: '#D2D3D3',
    onevisionBlack65: '#6D6F71',
    onevisionBlack50: '#8F9091',
    onevisionBlack80: '#4C4D50',
    primary: '#E9840E',
    secondary: '#2196F3',
    borderErrorColor: '#d32f2f',
    darkBlue: '#25384B',
  },
};

export type ExtTheme = Theme & typeof themeCustomParams;

export const standardParams = {
  palette: {
    primary: {
      light: '#54d0d3',
      main: '#2AC5C9',
      dark: '#1d898c',
    },
    secondary: {
      light: '#b47aff',
      main: '#A259FF',
      dark: '#713eb2',
    },
    divider: '#E5E5E5',
    background: {
      default: '#FBFCFD',
    },
  },
  typography: {
    useNextVariants: true,
  },
};

export const theme = createTheme({
  ...standardParams,
  ...themeCustomParams,
} as ThemeOptions);

export function applyTheme<T>(
  Component: React.ComponentType<T>,
): (props: T) => JSX.Element {
  const withAppliedTheme = (props: T): JSX.Element => {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Component {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };
  return withAppliedTheme;
}
