import { Typography, Box } from '@mui/material';
import React from 'react';
import { BackgroundInit } from '../../assets';
import { thumb } from '../../core/constants';
import { AfLazyLoadImage } from '../shared-components';
import { csn } from '@one-vision/utils';
import { AfDisconnectedIcon } from './af-disconnect-icon';
import { useStyles } from './af-error-boundary.styles';

export const AfAppLevelFallback: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <AfLazyLoadImage
        className={csn(classes.bgImage)}
        src={BackgroundInit}
        thumbnail={thumb}
      />
      <Box className={classes.wrapper}>
        <AfDisconnectedIcon />
        <Box className={classes.textWrapper}>
          <Typography
            variant="h4"
            className={classes.fallbackMessage}
            role="fallback-message"
          >
            Oops... We have a problem here!
          </Typography>
          <Typography className={classes.fallbackMessageSecondary}>
            Please refresh your page or contact us for more information
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
