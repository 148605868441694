import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.px2rem(20),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          paddingBottom: theme.px2rem(50),
        },
      },
      noMargin: {
        margin: 0,
      },
      header: {
        margin: `${theme.px2rem(20)} 0 ${theme.px2rem(40)} 0`,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginBottom: theme.px2rem(20),
        },
      },
      headerText: {
        fontSize: theme.px2rem(32),
        color: theme.colors.onevisionBlack,
        margin: 0,
        textAlign: 'center',
        lineHeight: theme.px2rem(40),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(15),
          lineHeight: theme.px2rem(20),
        },
      },
      hideHeaderText: {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      headerTextMob: {
        marginTop: theme.px2rem(15),
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      headerBold: {
        fontWeight: 500,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(18),
        },
      },
      headerDetails: {
        fontSize: theme.px2rem(14),
        color: theme.colors.onevisionBlack65,
        textAlign: 'center',
        margin: 0,
        marginTop: theme.px2rem(5),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      infoBlock: {
        borderRadius: '8px',
        border: `1px solid ${theme.colors.onevisionBlack20}`,
        padding: theme.px2rem(15),
        marginBottom: theme.px2rem(20),
      },
      editModeInfoBlock: {
        border: `1px solid ${theme.colors.primary}`,
      },
      infoBlockHeaderWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.px2rem(10),
      },
      infoBlockHeader: {
        color: theme.colors.onevisionBlack80,
        fontSize: theme.px2rem(16),
        fontWeight: 500,
        alignItems: 'center',
        display: 'flex',
      },
      editButton: {
        color: theme.colors.primary,
        padding: 0,
      },
      infoBlockIcon: {
        color: theme.colors.onevisionBlack80,
        marginRight: theme.px2rem(15),
      },
      infoBlockText: {
        color: theme.colors.onevisionBlack65,
        fontSize: theme.px2rem(16),
        fontWeight: 400,
      },
      inputSingle: {
        width: '100%',
      },
      inputDouble: {
        width: '48%',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
          '&:first-of-type': {
            marginBottom: theme.px2rem(15),
          },
        },
      },
      inputLine: {
        marginBottom: theme.px2rem(15),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          flexDirection: 'column',
        },
      },
      textToTOS: {
        color: theme.colors.onevisionBlack80,
      },
      linkToTOS: {
        color: theme.colors.primary,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginLeft: theme.px2rem(3),
        textDecoration: 'none',
      },
      checked: {
        '&$checked': {
          color: theme.colors.primary,
        },
      },
      mainButton: {
        backgroundColor: theme.colors.primary,
        color: '#fff',
        width: '50%',
        alignSelf: 'center',
        marginTop: theme.px2rem(15),
        height: theme.px2rem(36),
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness95,
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
        },
      },
      terms: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          justifyContent: 'flex-start',
        },
      },
      tosIcon: {
        width: theme.px2rem(146),
        height: theme.px2rem(161),
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      formHelperText: {
        marginTop: 0,
      },
    };
  },
  { name: 'af-tos-page' },
);
