import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { FormikContextType } from 'formik';
import { csn } from '@one-vision/utils';
import { AfLocationData } from 'components/shared-components/af-location-data';
import { useStyles } from '../af-checkout-step.styles';

interface Props {
  isSameAddress: boolean;
  setIsSameAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line
  formik: FormikContextType<any>;
}

export const AfBillingDetailsForm: React.FC<Props> = ({
  isSameAddress,
  setIsSameAddress,
  formik,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.sameAsServiceAddressCapt}>
        <Checkbox
          id="sameAddress"
          name="sameAddress"
          checked={isSameAddress}
          onChange={setIsSameAddress}
          classes={{
            root: csn(classes.checkbox, [classes.checked, isSameAddress]),
          }}
        />
        <Typography>Same as Service Address</Typography>
      </Box>
      {!isSameAddress && (
        <Box display="flex" flexDirection="column" width="100%">
          <AfLocationData formik={formik} />
        </Box>
      )}
    </Box>
  );
};
