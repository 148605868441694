import React from 'react';
import {
  Stepper,
  StepConnector,
  Step,
  StepLabel,
  Box,
  StepIconProps,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { csn } from '@one-vision/utils';
import { Step as StepEnum } from '../../../core/redux/slices/step.redux';
import { FormStep } from '../af-root.view';
import { useStyles } from './stepper.styles';

const StepIcon: React.FC<StepIconProps> = (props) => {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div className={csn(classes.stepIconRoot)}>
      {completed ? (
        <div
          className={csn(
            classes.stepIconCircle,
            classes.stepIconCircleFilled,
          )}
        >
          <Check className={classes.stepIconCompleted} />
        </div>
      ) : (
        <div
          className={csn(classes.stepIconCircle, [
            classes.stepIconCircleActiveFilled,
            active,
          ])}
        />
      )}
    </div>
  );
};

const steps = [
  'Accept Terms of Service',
  'View Membership Options',
  'Payment (optional)',
  'Confirmation',
];

export const StepperRoot: React.FC<{
  step: StepEnum;
  activeStep: FormStep;
}> = ({ step, activeStep }) => {
  const classes = useStyles();

  if (activeStep === FormStep.Verify) {
    return null;
  }

  return (
    <Stepper
      className={csn(classes.customStepper, [
        classes.customStepperHidden,
        step !== StepEnum.None,
      ])}
      activeStep={activeStep}
      orientation="horizontal"
      alternativeLabel
      connector={
        <StepConnector
          classes={{
            active: classes.customConnectorActive,
            completed: classes.customConnectorActive,
            line: classes.customConnector,
          }}
        />
      }
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            classes={{
              label: classes.stepperLabelHidden,
            }}
            StepIconComponent={StepIcon}
          >
            <Box className={classes.stepperText}>{label}</Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
