import React from 'react';
import { Box, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StarIcon from '@mui/icons-material/Star';
import { csn } from '@one-vision/utils';
import { priceDecoration } from 'core/utils';
import { useStyles } from './af-option-button.styles';

export const AfOptionButtonView: React.FC<{
  name: string;
  fee: number;
  isYearly: boolean;
  recommended: boolean;
  recommendedText: string;
  active: boolean;
  callForPricing: string | null;
  onClick: () => void;
}> = ({
  name,
  fee,
  isYearly,
  active,
  recommended,
  recommendedText,
  callForPricing,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.optionWrapper}>
      <Button
        classes={{
          root: csn(classes.optionButton, [
            classes.optionButtonActive,
            active,
          ]),
        }}
        onClick={onClick}
        data-testid={`option-button-${name}`}
      >
        <Box className={classes.optionButtonLeft}>
          {active ? (
            <CheckCircleIcon data-testid={`checked-option-${name}`} />
          ) : (
            <RadioButtonUncheckedIcon
              data-testid={`unchecked-option-${name}`}
            />
          )}
          <Box>{name}</Box>
        </Box>
        <Box
          className={csn(classes.optionButtonRight, [
            classes.optionButtonRightContact,
            !!callForPricing,
          ])}
        >
          {callForPricing ? (
            <>
              <Box>Contact Us</Box>
              <Box>{callForPricing}</Box>
            </>
          ) : (
            <>
              <Box>{`$${priceDecoration(fee)}`}</Box>
              <Box>{isYearly ? '/yr' : '/mo'}</Box>
            </>
          )}
        </Box>
      </Button>
      {!!recommended && (
        <Box
          className={classes.recommendationLabel}
          data-testid={`recommended-option-${name}`}
        >
          <Box className={classes.recommend}>
            <StarIcon />
            <Box>{recommendedText}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
