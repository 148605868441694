import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (_: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      fillNone: {
        fill: 'none',
      },
    };
  },
  { name: 'af-icons' },
);
