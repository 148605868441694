type OvReactAppStage = 'prod' | 'sb' | 'dev' | 'local';

const api =
  (process.env.REACT_APP_API_URL as string) +
  // we need this part for custom environments (ada|eve|mia|zoe etc)
  ((process.env.REACT_APP_API_URL_SUFFIX as string) || '');

const activationFlowApi = process.env.REACT_APP_AF_API_URL as string;

export const afConfig = {
  stage: process.env.REACT_APP_STAGE as OvReactAppStage,
  api,
  activationFlowApi,
  errorsApi: process.env.REACT_APP_ERRORS_API_URL as string,
  apiKeys: {
    // will be set after auth
    zpApiKey: 'test-token-xxxxxxxxxx',
    errorsApiKey: '',
  },
  stripeKey: process.env.REACT_APP_STRIPE_KEY as string,
};

if (afConfig.stage !== 'prod') {
  console.log(`stage: ${afConfig.stage?.toUpperCase()}`);
}
