import React, { useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { csn } from '@one-vision/utils';
import { MembershipSteps } from '../../../../../core/redux/slices/membership-step.redux';
import { useStyles } from './af-choose-way.styles';

export const ChooseWayView: React.FC<{
  setStep: (step: MembershipSteps) => void;
}> = ({ setStep }) => {
  const classes = useStyles();

  const helpStepHandler = useCallback(
    () => setStep(MembershipSteps.HelpMe1),
    [setStep],
  );
  const optionsStepHandler = useCallback(
    () => setStep(MembershipSteps.Options),
    [setStep],
  );

  return (
    <Box className={classes.intermediateStep}>
      <Typography variant="h3" className={classes.header}>
        Let’s figure out how to best support you.
      </Typography>
      <Typography variant="h2" className={classes.headerText}>
        From remote assistance to same-day appointments, we can curate an
        experience for you.
      </Typography>
      <Box className={classes.chooseButtonsWrapper}>
        <Button
          classes={{ root: csn(classes.choiceButton, classes.helpButton) }}
          onClick={helpStepHandler}
        >
          Help me choose
        </Button>
        <Button
          classes={{
            root: csn(classes.choiceButton, classes.optionsButton),
          }}
          onClick={optionsStepHandler}
        >
          View my Options
        </Button>
      </Box>
    </Box>
  );
};
