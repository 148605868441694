import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import { AfGirlIcon } from '../../shared-components/af-icons';
import { actions, usePartnerConfigRule } from '../../../core/redux';
import { AfFaqBody } from './af-faq-text.view';
import { useStyles } from './af-footer.styles';
import { PartnerGroupRule } from 'core/types';

export const AfFooterView: React.FC<{ show: boolean }> = ({ show }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const chatOpenHandler = useCallback(() => {
    dispatch(actions.updateFooter({ showChat: true }));
  }, [dispatch]);

  const accessToSubscriptionManagementPlus = usePartnerConfigRule(
    PartnerGroupRule.AccessToSubscriptionManagementPlus,
  );

  if (show) {
    return (
      <footer className={classes.footer}>
        <AfFaqBody />
        {accessToSubscriptionManagementPlus && (
          <div className={classes.help}>
            <Typography>Need more help?</Typography>
            <Typography>
              Schedule a 15-minute consultation with one of our team
              members.
            </Typography>
            <Box className={classes.girlIconWrapper}>
              <AfGirlIcon classes={{ root: classes.girlIcon }} />
              <Button
                classes={{ root: classes.chatButton }}
                onClick={chatOpenHandler}
              >
                Chat with a membership expert
              </Button>
            </Box>
          </div>
        )}
      </footer>
    );
  } else {
    return null;
  }
};
