import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { PaymentMethod } from 'core/types';
import { AfMastercardIcon } from '../af-cc-icons/af-mastercard-icon';
import { AfVisaIcon } from '../af-cc-icons/af-visa-icon';
import { AfAmericanExpressIcon } from '../af-cc-icons/af-amex-icon';
import { AfDiscoverIcon } from '../af-cc-icons/af-discover-icon';
import { AfDinersIcon } from '../af-cc-icons/af-diners-icon';
import { AfJcbIcon } from '../af-cc-icons/af-jcb-icon';
import { AfUnionPayIcon } from '../af-cc-icons/af-union-pay-icon';
import { AfCartesBancairesIcon } from '../af-cc-icons/af-cartes-bancaires-icon';
import { csn } from '@one-vision/utils';
import { useStyles } from './af-payment-method-item.styles';

const getIcon = (item: PaymentMethod['card']['brand']) => {
  switch (item) {
    case 'amex':
      return <AfAmericanExpressIcon />;
    case 'mastercard':
      return <AfMastercardIcon />;
    case 'visa':
      return <AfVisaIcon />;
    case 'discover':
      return <AfDiscoverIcon />;
    case 'diners':
      return <AfDinersIcon />;
    case 'jcb':
      return <AfJcbIcon />;
    case 'unionpay':
      return <AfUnionPayIcon />;
    case 'cartes_bancaires':
      return <AfCartesBancairesIcon />;
    default:
      return <CreditCardIcon />;
  }
};

export const AfPaymentMethodItem: React.FC<PaymentMethod> = ({
  card: { brand, last4, exp_month, exp_year },
  default: isDefault,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={csn(classes.ccImage, classes.cell)}>
        {getIcon(brand)}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        className={csn(classes.cell, classes.details)}
      >
        <Typography
          className={classes.ccDetails}
        >{`${brand.toLocaleUpperCase()} xxxx ${last4}`}</Typography>
        <Typography className={classes.ccExp}>{`Expires ${
          exp_month < 10 ? `0${exp_month}` : exp_month
        }/${exp_year}`}</Typography>
      </Box>
      <Box className={classes.cell}>
        {isDefault ? (
          <Chip label="Default" classes={{ root: classes.chip }} />
        ) : null}
      </Box>
    </Box>
  );
};
