import * as yup from 'yup';
import { placeholders } from './placeholders';

export const schemaFields = {
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup
    .string()
    .email(
      `Please enter a valid email address. Valid format: ${placeholders.email}`,
    ),
  address1: yup.string().required('Required'),
  address2: yup.string(),
  city: yup.string().required('Required'),
  state: yup.string().required('Required'),
  zip: yup.number().required('Required'),
  cardCompleted: yup.boolean().isTrue('Required'),
  baseRequiredString: (field?: string) =>
    yup.string().required(`${field} is required`),
  fullName: yup.string().required('Required'),
  phone: yup
    .string()
    .matches(
      /^\+1 \(\d{1,3}\) \d{3}-\d{4}$/,
      `Please enter a valid phone number. Valid formats: ${placeholders.phone}`,
    ),
};

export const schemas = {
  clientData: yup.object({
    email: schemaFields.email,
    firstName: schemaFields.firstName,
    lastName: schemaFields.lastName,
    primaryPhone: schemaFields.phone,
  }),
  billingData: yup.object({
    fullName: schemaFields.fullName,
    email: schemaFields.email.required('Required'),
    cardCompleted: schemaFields.cardCompleted,
    sameAddress: yup.boolean().required('Required'),
    address1: yup.string().when('sameAddress', {
      is: false,
      then: yup.string().required('Required'),
    }),
    address2: yup.string(),
    city: yup.string().when('sameAddress', {
      is: false,
      then: yup.string().required('Required'),
    }),
    state: yup.string().when('sameAddress', {
      is: false,
      then: yup.string().required('Required'),
    }),
    zip: yup.string().when('sameAddress', {
      is: false,
      then: yup.string().required('Required'),
    }),
  }),
};
