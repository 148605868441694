import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles((theme: ExtTheme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: theme.px2rem(500),
    },
    topText: {
      color: theme.colors.onevisionBlack50,
      fontSize: theme.px2rem(26),
      fontWeight: 500,
      marginBottom: theme.px2rem(25),
    },
    heading: {
      color: theme.colors.onevisionBlack50,
      fontSize: theme.px2rem(32),
      fontWeight: 700,
      marginTop: theme.px2rem(25),
      textAlign: 'center',
    },
    body: {
      color: theme.colors.onevisionBlack65,
      fontSize: theme.px2rem(16),
      fontWeight: 500,
      textAlign: 'center',
    },
    bottom: {
      color: theme.colors.onevisionBlack65,
      fontSize: theme.px2rem(16),
      fontWeight: 500,
      textAlign: 'center',
      marginTop: theme.px2rem(70),
    },
  };
});
