import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { footerSlice } from './slices/footer.redux';
import { stepSlice } from './slices/step.redux';
import { answersSlice } from './slices/answers.redux';
import { membershipStepsSlice } from './slices/membership-step.redux';
import { snackbarSlice } from './slices/snackbar.redux';
import { partnerConfigSlice } from './slices/partner-config.redux';
import {
  activationFlowDataSlice,
  getAfData,
} from './slices/af-data.redux';

const rootReducer = combineReducers({
  footer: footerSlice.reducer,
  step: stepSlice.reducer,
  answers: answersSlice.reducer,
  membershipSteps: membershipStepsSlice.reducer,
  snackbar: snackbarSlice.reducer,
  afData: activationFlowDataSlice.reducer,
  partnerConfig: partnerConfigSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AfStore = ReturnType<typeof store.getState>;

export const actions = {
  ...footerSlice.actions,
  ...stepSlice.actions,
  ...answersSlice.actions,
  ...membershipStepsSlice.actions,
  ...snackbarSlice.actions,
  ...activationFlowDataSlice.actions,
  ...partnerConfigSlice.actions,
};

export const thunks = { getAfData };

export const useAfSelector: TypedUseSelectorHook<AfStore> = useSelector;

export const usePartnerConfigRule = (ruleName: string) =>
  useAfSelector(
    (state) =>
      state.partnerConfig.find((el) => el.partnerGroupRuleId === ruleName)
        ?.value || (false as boolean),
  );
