import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useAfSelector, usePartnerConfigRule } from 'core/redux';
import {
  PartnerGroupRule,
  ProductOffered,
  UpcomingInvoiceResponse,
} from 'core/types';
import { sumDecoration, priceHandler, checkBuyNow } from 'core/utils';
import { csn } from '@one-vision/utils';
import { AfFeaturesList } from '../af-features-list';
import { AfCouponInput } from '../af-coupon-input';
import { useStyles } from '../af-checkout-step.styles';

const ONE_HUNDRED_PERCENT = 1;

const EXPIRES_ON =
  'Your card won’t be charged until after your complimentary period expires on';
const EXPIRES_ON_MEMBERSHIP_EXP =
  'Your card will not be charged until after your complimentary period expires';

interface Props {
  previousStep: () => void;
  setCouponId: (name: string) => void;
  submitPayment: () => void;
  setIsLoading: (status: boolean) => void;
  loading: boolean;
}

export const AfCalculationSection: React.FC<Props> = ({
  previousStep,
  setCouponId,
  submitPayment,
  setIsLoading,
  loading,
}) => {
  const classes = useStyles();

  const [couponAmount, setCouponAmount] = useState(0);

  const afData = useAfSelector((state) => state.afData);

  const accessToPreventChargeClientsPreLits = usePartnerConfigRule(
    PartnerGroupRule.PreventChargeClientsPreLits,
  );

  const { plan, upcomingInvoice, yearly } = useAfSelector(
    (state) => state.membershipSteps,
  ) as {
    plan: ProductOffered;
    upcomingInvoice: UpcomingInvoiceResponse;
    yearly: boolean;
  };

  const couponHandler = useCallback(
    (amount: number) => {
      setCouponAmount(amount);
    },
    [couponAmount],
  );

  const extraNotes = useMemo(
    () =>
      checkBuyNow(
        afData,
        EXPIRES_ON,
        EXPIRES_ON_MEMBERSHIP_EXP,
        accessToPreventChargeClientsPreLits,
      ),
    [afData, accessToPreventChargeClientsPreLits],
  );

  return (
    <Box className={classes.rightColumn}>
      <Box className={classes.infoBlock}>
        <Typography className={classes.rightHeader}>
          Order Summary
        </Typography>
        <Box className={classes.infoLine}>
          <Typography className={classes.blockCaption}>
            SERVICE ADDRESS
          </Typography>
          <Box>
            <Typography className={classes.serviceAddress}>
              {`${afData.address1}${
                afData.address2 ? `, ${afData.address2}` : ''
              }`}
            </Typography>
            <Typography className={classes.serviceAddress}>
              {`${afData.city}, ${afData.state} ${afData.zip}`}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.infoLine}>
          <Typography className={classes.blockCaption}>
            MEMBERSHIP SELECTION
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box className={classes.featuresWrapper}>
              <Box className={classes.editPlanWrapper}>
                <Typography className={classes.membershipHeader}>
                  {plan.productName}
                </Typography>
                <Button
                  className={classes.editPlan}
                  onClick={previousStep}
                >
                  EDIT PLAN
                </Button>
              </Box>
              <Box className={classes.priceSumWrapperMob}>
                <Typography className={classes.priceSum}>
                  {`$${
                    upcomingInvoice
                      ? sumDecoration(upcomingInvoice.unitAmount / 100)
                      : sumDecoration(priceHandler(afData, plan, yearly))
                  }`}
                </Typography>
                <Typography className={classes.period}>
                  {`Billed ${
                    (upcomingInvoice &&
                      upcomingInvoice.interval === 'year') ||
                    yearly
                      ? '[Yearly]\u{002A}'
                      : '[Monthly]'
                  }`}
                </Typography>
              </Box>
              <AfFeaturesList />
            </Box>
            <Box className={classes.priceSumWrapper}>
              <Typography className={classes.priceSum}>
                {`$${
                  upcomingInvoice
                    ? sumDecoration(upcomingInvoice.unitAmount / 100)
                    : sumDecoration(priceHandler(afData, plan, yearly))
                }`}
              </Typography>
              <Typography className={classes.period}>
                {`Billed ${
                  (upcomingInvoice &&
                    upcomingInvoice.interval === 'year') ||
                  yearly
                    ? '[Yearly]\u{002A}'
                    : '[Monthly]'
                }`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <AfCouponInput
          data={afData}
          loading={loading}
          setIsLoading={setIsLoading}
          amount={couponAmount}
          couponSet={couponHandler}
          setCouponId={setCouponId}
        />
        {plan.tax && (
          <Box
            className={csn(classes.infoLine, classes.infoLineTaxes)}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography
              className={csn(classes.blockCaption, classes.taxesCaption)}
            >
              TAXES
            </Typography>
            <Typography className={classes.taxesSum}>
              {`$${
                upcomingInvoice
                  ? (upcomingInvoice.tax && upcomingInvoice.tax / 100) || 0
                  : sumDecoration(
                      (priceHandler(afData, plan, yearly) - couponAmount) *
                        plan.taxPercent,
                    )
              }`}
            </Typography>
          </Box>
        )}
        <Box className={classes.totalBlock}>
          <Box className={classes.totalLine}>
            <Typography className={classes.totalCaption}>
              Total today
            </Typography>
            <Typography className={classes.totalSum}>
              {`$${
                (upcomingInvoice &&
                  sumDecoration(upcomingInvoice.amountDue / 100)) ||
                sumDecoration(
                  (priceHandler(afData, plan, yearly) - couponAmount) *
                    (plan.tax
                      ? ONE_HUNDRED_PERCENT + plan.taxPercent
                      : ONE_HUNDRED_PERCENT),
                )
              }`}
            </Typography>
          </Box>
          <Button
            className={classes.signUpButton}
            onClick={submitPayment}
            disabled={loading}
          >
            Sign Up
            {loading ? (
              <CircularProgress
                classes={{
                  root: csn(classes.spinner, [
                    classes.graySpinner,
                    loading,
                  ]),
                }}
                size="2rem"
              />
            ) : null}
          </Button>
          <Button
            className={classes.signUpButtonMob}
            onClick={submitPayment}
            disabled={loading}
          >
            {`PAY $${sumDecoration(
              (priceHandler(afData, plan, yearly) - couponAmount) *
                (plan.tax
                  ? ONE_HUNDRED_PERCENT + plan.taxPercent
                  : ONE_HUNDRED_PERCENT),
            )}`}
            {loading ? (
              <CircularProgress
                classes={{
                  root: csn(classes.spinner, [
                    classes.graySpinner,
                    loading,
                  ]),
                }}
                size="1.5rem"
              />
            ) : null}
          </Button>
          {extraNotes && (
            <Box className={classes.note}>
              <Typography className={classes.notesCaption}>
                Please note:
              </Typography>
              <Typography className={classes.noteText}>
                {extraNotes}
              </Typography>
              {yearly && (
                <Typography className={classes.italic}>
                  &#42;Annual memberships are non-refundable.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {extraNotes && (
        <Box className={classes.noteMob}>
          <Typography className={classes.notesCaption}>
            Please note:
          </Typography>
          <Typography className={classes.noteText}>
            {extraNotes}
          </Typography>
          {yearly && (
            <Typography className={classes.italic}>
              &#42;Annual memberships are non-refundable.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
