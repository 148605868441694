import React, { useCallback, MutableRefObject } from 'react';
import { Box, InputLabel, FormHelperText } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { FormikContextType } from 'formik';
import { csn } from '@one-vision/utils';
import { AfInput } from 'components/shared-components/af-input';
import { CARD_COMPLETED_FIELD } from '../af-checkout-step.view';
import { useStyles } from '../af-checkout-step.styles';

interface Pops {
  // eslint-disable-next-line
  formik: FormikContextType<any>;
  stripeElement: MutableRefObject<StripeCardElement | null>;
  errorCC: string;
  setCcError: (err: string) => void;
}

export const AfCcForm: React.FC<Pops> = ({
  formik,
  stripeElement,
  errorCC,
  setCcError,
}) => {
  const classes = useStyles();

  const cardElementHandler = useCallback(
    (e: StripeCardElement) => {
      stripeElement.current = e;
    },
    [stripeElement],
  );
  const cardElementChangeHandler = useCallback(
    (e: StripeCardElementChangeEvent) => {
      formik.setFieldValue(CARD_COMPLETED_FIELD, e.complete);
      formik.setFieldTouched(CARD_COMPLETED_FIELD, false);
      if (e.error) {
        setCcError(e.error.message);
        return;
      }
      if (errorCC) {
        setCcError('');
      }
    },
    [formik, setCcError],
  );

  const isDisabled =
    (!formik.touched[CARD_COMPLETED_FIELD] &&
      !formik.errors[CARD_COMPLETED_FIELD]) ||
    !errorCC;

  const isError =
    (formik.touched[CARD_COMPLETED_FIELD] &&
      !!formik.errors[CARD_COMPLETED_FIELD]) ||
    !!errorCC;

  return (
    <>
      <Box className={classes.inputLine}>
        <AfInput
          formik={formik}
          label="Cardholder Name"
          type="text"
          name="fullName"
        />
      </Box>
      <Box className={classes.inputLine}>
        <AfInput
          formik={formik}
          label="Billing Email"
          type="email"
          name="email"
        />
      </Box>
      <Box className={classes.stripeElements}>
        <Box className={classes.inputLine} flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <InputLabel className={classes.labelStyled}>
              Card Number
            </InputLabel>
            <FormHelperText
              disabled={isDisabled}
              error={isError}
              className={classes.noMarginTop}
            >
              {(formik.touched[CARD_COMPLETED_FIELD] &&
                formik.errors[CARD_COMPLETED_FIELD]) ||
                errorCC}
            </FormHelperText>
          </Box>
          <CardElement
            options={{
              classes: {
                base: csn(classes.inputStyled, [
                  classes.inputStyledError,
                  isError,
                ]),
              },
              hidePostalCode: true,
            }}
            onReady={cardElementHandler}
            onChange={cardElementChangeHandler}
          />
        </Box>
      </Box>
    </>
  );
};
