import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Step {
  Membership,
  Payment,
  None,
}

interface State {
  step: Step;
}

export const stepSlice = createSlice({
  name: 'step',
  initialState: { step: Step.None } as State,
  reducers: {
    updateStep: (state, action: PayloadAction<Partial<State>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});
