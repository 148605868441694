import React from 'react';
import { Box, InputBase, InputLabel, FormHelperText } from '@mui/material';
import { FormikContextType } from 'formik';
import { csn } from '@one-vision/utils';
import NumberFormat from 'react-number-format';
import { ActivationFlowData } from '../../../core/types';
import { useStyles } from './af-phone-input.styles';

interface Props {
  formik: FormikContextType<ActivationFlowData>;
  name: keyof Partial<ActivationFlowData>;
  label: string;
}

export const AfPhoneInput: React.FC<Props> = ({ formik, name, label }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <InputLabel htmlFor={name} className={classes.labelStyled}>
          {label}
        </InputLabel>
        <FormHelperText
          disabled={!formik.touched[name] && !formik.errors[name]}
          error={formik.touched[name] && !!formik.errors[name]}
          className={classes.formHelperText}
        >
          {formik.touched[name] && formik.errors[name]}
        </FormHelperText>
      </Box>
      <NumberFormat
        customInput={InputBase}
        placeholder={'+1 (212) 000-0000'}
        format="+1 (###) ###-####"
        mask="_"
        id={name}
        value={formik.values[name] as string}
        onChange={formik.handleChange}
        name={name}
        error={formik.touched[name] && !!formik.errors[name]}
        className={csn(classes.inputStyled)}
        classes={{ error: classes.errorInput }}
      />
    </Box>
  );
};
