import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Answers {
  NoOrNever = 0,
  YesOrAlways = 1,
  Sometimes = 2,
  nullable = 100,
}

interface State {
  question1: Answers;
  question2: Answers;
  question3: Answers;
}

export const answersSlice = createSlice({
  name: 'answers',
  initialState: {
    question1: Answers.nullable,
    question2: Answers.nullable,
    question3: Answers.nullable,
  } as State,
  reducers: {
    updateAnswers: (state, action: PayloadAction<Partial<State>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});
