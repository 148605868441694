const ensureRoot = (rootId: string) => {
  let root = document.getElementById('root');

  if (root) {
    return root;
  }
  root = document.createElement('div');
  root.id = rootId;
  document.body.appendChild(root);

  return root;
};

export default ensureRoot;
