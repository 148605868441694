import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => ({
    labelStyled: {
      marginBottom: theme.px2rem(3),
      fontSize: theme.px2rem(12),
      color: theme.colors.onevisionBlack80,
    },
    inputStyled: {
      backgroundColor: '#F5F5F5',
      borderRadius: '8px',
      padding: `${theme.px2rem(5)} ${theme.px2rem(10)}`,
      color: theme.colors.onevisionBlack80,
      height: theme.px2rem(50),
      fontSize: theme.px2rem(14),
      fontWeight: 500,
      border: 'none',
      width: '100%',
    },
    formHelperText: {
      marginTop: 0,
    },
    errorInput: {
      border: '1px solid #f44336',
    },
  }),
  { name: 'af-input' },
);
