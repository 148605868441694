import React from 'react';
import { Box, Typography } from '@mui/material';
import verifyIcon from '../../../assets/images/verify-email.svg';
import { useStyles } from './af-verify-email.styles';

export const AfVerifyEmailStep: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.topText}>Thank you!</Typography>
      <img src={verifyIcon} />
      <Typography className={classes.heading}>
        Let’s verify your info...
      </Typography>
      <Typography className={classes.body}>
        Looks like you’ve updated some of your contact information. To keep
        things secure, please click on the link we emailed you to continue
        the registration process!
      </Typography>
      <Typography className={classes.bottom}>
        You may close this browser tab.
      </Typography>
    </Box>
  );
};
