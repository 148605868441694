import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentMethodResult, StripeCardElement } from '@stripe/stripe-js';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
} from '@mui/material';
import { csn } from '@one-vision/utils';
import { schemas } from '../../shared-components/af-forms';
import { useFormik } from 'formik';
import { Step } from '../../../core/redux/slices/step.redux';
import { actions, useAfSelector } from '../../../core/redux';
import {
  ProductOffered,
  AfAsyncDispatch,
  ErrorResponse,
  PaymentMethod,
  UpcomingInvoiceResponse,
  SubscriptionDetails,
} from 'core/types';
import { AfPaymentMethodItem } from './af-payment-method-item';
import { AfCcForm } from './af-cc-form';
import { AfCalculationSection } from './af-calculation-section';
import { AfBillingDetailsForm } from './af-billing-details-form';
import { AfConfirmIdentityModal } from './af-confirm-identity-modal';
import { usePaymentSubmit } from './usePaymentSubmit';
import { useStyles } from './af-checkout-step.styles';

export const CARD_COMPLETED_FIELD = 'cardCompleted';
const NEW_PAYMENT_METHOD = 'new';

export interface CheckoutFormikData {
  fullName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  cardCompleted: boolean;
  sameAddress: boolean;
}

interface Props {
  nextStep: () => void;
  previousStep: () => void;
}

export const AfCheckOutStepView: React.FC<Props> = ({
  previousStep,
  nextStep,
}) => {
  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();
  const stripeElement = useRef<StripeCardElement | null>(null);

  const [sameAddress, setSameAddress] = useState(true);
  const [couponId, setCouponId] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorCC, setErrorCC] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch<AfAsyncDispatch>();

  const { plan, paymentMethods, subscriptionDetails } = useAfSelector(
    (state) => state.membershipSteps,
  ) as {
    plan: ProductOffered;
    paymentMethods: PaymentMethod[];
    yearly: boolean;
    upcomingInvoice: UpcomingInvoiceResponse | null;
    subscriptionDetails: SubscriptionDetails | null;
  };

  const afData = useAfSelector((state) => state.afData);

  const [paymentMethod, setPaymentMethod] = useState(
    paymentMethods?.find((el) => el.default)?.id || null,
  );

  useEffect(() => {
    dispatch(actions.updateStep({ step: Step.Payment }));

    return () => {
      dispatch(actions.updateStep({ step: Step.None }));
    };
  }, []);

  const {
    getSetupIntent,
    createPaymentMethod,
    confirmCardSetup,
    submitPay,
  } = usePaymentSubmit(
    stripe,
    stripeElement,
    setErrorCC,
    setLoading,
    couponId,
  );

  const initialValues = {
    fullName: `${afData.firstName} ${afData.lastName}`,
    email: afData.email,
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    cardCompleted: false,
    sameAddress: true,
  };
  const validationSchema = schemas.billingData;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (formikData) => {
      setLoading(true);

      let usedPaymentMethodId = paymentMethod;
      if (
        !usedPaymentMethodId ||
        usedPaymentMethodId === NEW_PAYMENT_METHOD
      ) {
        const createdPaymentMethod = (await createPaymentMethod(
          formikData,
        )) as PaymentMethodResult;
        usedPaymentMethodId = createdPaymentMethod.paymentMethod
          ?.id as string;
      }

      const clientSecret = await getSetupIntent(
        subscriptionDetails?.customerId || '',
      );

      if (usedPaymentMethodId) {
        try {
          await confirmCardSetup(usedPaymentMethodId, clientSecret);

          const selectedPlanName =
            plan.productName === 'Essentials+'
              ? 'Essentials Plus'
              : plan.productName;

          const submitPaymentRes = await submitPay(
            usedPaymentMethodId,
            formikData,
            afData.activeSubscription,
          );

          const {
            data: { data: response },
          } = submitPaymentRes;

          dispatch(
            actions.updateData({
              creditCardProvided: true,
              activeSubscription: response.activeSubscription,
              serviceLevel: selectedPlanName,
              desiredMembership: selectedPlanName,
            }),
          );
          dispatch(
            actions.updateSnackbar({
              text: response.result,
              type: 'success',
            }),
          );
          nextStep();
        } catch (error) {
          const err = error as ErrorResponse;
          if (err.response?.data?.errors?.length) {
            dispatch(
              actions.updateSnackbar({
                text: err.response.data.errors[0].detail,
                type: 'error',
              }),
            );
          }
        } finally {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (!afData.activeSubscription || !paymentMethod) {
      return;
    }
    if (paymentMethod !== NEW_PAYMENT_METHOD) {
      formik.setFieldValue(CARD_COMPLETED_FIELD, true);
      formik.setFieldTouched(CARD_COMPLETED_FIELD, false);
    }
    if (paymentMethod === NEW_PAYMENT_METHOD) {
      formik.setFieldValue(CARD_COMPLETED_FIELD, false);
    }
  }, [paymentMethod, afData]);

  const setCcError = useCallback(
    (error: string) => {
      setErrorCC(error);
    },
    [setErrorCC],
  );

  const checkAddressHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSameAddress(!sameAddress);
      formik.handleChange(e);
    },
    [sameAddress],
  );

  const submitPayment = useCallback(() => {
    if (
      afData.activeSubscription &&
      paymentMethod !== NEW_PAYMENT_METHOD
    ) {
      setOpenModal(true);
      return;
    }
    formik.handleSubmit();
  }, [formik, stripe, elements, stripeElement, afData, paymentMethod]);

  const submitPaymentOnValidation = useCallback(
    (valid: boolean) => {
      if (valid) {
        formik.handleSubmit();
      }
    },
    [formik, stripe, elements, stripeElement, paymentMethod],
  );

  const handlePaymentMethod = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentMethod((event.target as HTMLInputElement).value);
    },
    [],
  );

  const modalClose = useCallback(() => {
    setOpenModal(false);
  }, [openModal]);

  return (
    <Box className={classes.root}>
      <Box className={classes.leftColumn}>
        <Typography className={classes.leftHeader}>Payment</Typography>
        {afData.activeSubscription ? (
          <Box className={classes.paymentBlock}>
            <Typography className={classes.paymentBlockTitle}>
              Choose payment method
            </Typography>
            <RadioGroup
              value={paymentMethod}
              onChange={handlePaymentMethod}
            >
              {paymentMethods &&
                [...paymentMethods]
                  .sort((el) => (el.default ? -1 : 1))
                  .map((el) => (
                    <FormControlLabel
                      key={el.id}
                      value={el.id}
                      control={
                        <Radio classes={{ checked: classes.radioColor }} />
                      }
                      label={<AfPaymentMethodItem key={el.id} {...el} />}
                    />
                  ))}
              <FormControlLabel
                value={NEW_PAYMENT_METHOD}
                control={
                  <Radio
                    classes={{ checked: classes.radioColor }}
                    className={csn([
                      classes.displayNone,
                      paymentMethod !== NEW_PAYMENT_METHOD,
                    ])}
                  />
                }
                className={classes.newCard}
                label={
                  <Box
                    className={csn([
                      classes.addNewCard,
                      paymentMethod !== NEW_PAYMENT_METHOD,
                    ])}
                  >
                    + Add new card
                  </Box>
                }
              />
            </RadioGroup>
            <Collapse in={paymentMethod === NEW_PAYMENT_METHOD}>
              <AfCcForm
                formik={formik}
                stripeElement={stripeElement}
                errorCC={errorCC}
                setCcError={setCcError}
              />
              <AfBillingDetailsForm
                formik={formik}
                isSameAddress={sameAddress}
                setIsSameAddress={checkAddressHandler}
              />
            </Collapse>
          </Box>
        ) : (
          <Box>
            <Typography className={classes.ccInfoTitle}>
              Credit Card Info
            </Typography>
            <AfCcForm
              formik={formik}
              stripeElement={stripeElement}
              errorCC={errorCC}
              setCcError={setCcError}
            />
            <Typography className={classes.billingAddress}>
              Billing Address
            </Typography>
            <AfBillingDetailsForm
              formik={formik}
              isSameAddress={sameAddress}
              setIsSameAddress={checkAddressHandler}
            />
          </Box>
        )}
      </Box>
      <AfCalculationSection
        loading={loading}
        setIsLoading={setLoading}
        previousStep={previousStep}
        setCouponId={setCouponId}
        submitPayment={submitPayment}
      />
      <AfConfirmIdentityModal
        isOpen={openModal}
        onClose={modalClose}
        onValidationSubmit={submitPaymentOnValidation}
      />
    </Box>
  );
};
