import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductOffered,
  UpcomingInvoiceResponse,
  PaymentMethod,
  SubscriptionDetails,
} from '../../types';

export enum MembershipSteps {
  ChooseWay = 0,
  HelpMe1 = 1,
  HelpMe2 = 2,
  HelpMe3 = 3,
  Options = 4,
}

export enum Options {
  Essential = 1,
  Priority = 2,
  Proactive = 3,
  Signature = 4,
  nullable = 5,
}

interface State {
  internalStep: MembershipSteps;
  plan: ProductOffered | null;
  yearly: boolean;
  upcomingInvoice: UpcomingInvoiceResponse | null;
  paymentMethods: PaymentMethod[] | null;
  subscriptionDetails: SubscriptionDetails | null;
}

export const membershipStepsSlice = createSlice({
  name: 'membershipSteps',
  initialState: {
    internalStep: MembershipSteps.ChooseWay,
    plan: null,
    yearly: false,
    upcomingInvoice: null,
    paymentMethods: null,
    subscriptionDetails: null,
  } as State,
  reducers: {
    updateMembershipSteps: (
      state,
      action: PayloadAction<Partial<State>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});
