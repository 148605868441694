import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'grid',
        gridTemplateColumns: '1.7fr 2fr',
        columnGap: theme.px2rem(30),
        padding: `${theme.px2rem(15)} 0`,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          gridTemplateColumns: '1fr',
        },
      },
      leftColumn: {
        display: 'flex',
        flexDirection: 'column',
      },
      rightColumn: {
        display: 'flex',
        flexDirection: 'column',
      },
      leftHeader: {
        fontSize: theme.px2rem(38),
        color: theme.colors.black,
        marginBottom: theme.px2rem(25),
        fontWeight: 'bold',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(18),
        },
      },
      inputLine: {
        marginBottom: theme.px2rem(15),
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          flexDirection: 'column',
        },
      },
      inputLineExp: {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          flexDirection: 'row',
        },
      },
      inputDouble: {
        width: '48%',
      },
      ccInfoTitle: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        marginBottom: theme.px2rem(15),
        fontWeight: 500,
      },
      labelStyled: {
        marginBottom: theme.px2rem(3),
        fontSize: theme.px2rem(12),
        color: theme.colors.onevisionBlack80,
      },
      inputStyled: {
        backgroundColor: '#F5F5F5',
        borderRadius: '8px',
        padding: `${theme.px2rem(5)} ${theme.px2rem(10)}`,
        color: theme.colors.onevisionBlack80,
        height: theme.px2rem(50),
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        border: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > div': {
          width: '100%',
        },
      },
      inputStyledError: {
        border: `1px solid ${theme.colors.borderErrorColor}`,
      },
      billingAddress: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        marginBottom: theme.px2rem(15),
        fontWeight: 500,
      },
      stripeElements: {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginBottom: theme.px2rem(25),
        },
      },
      sameAsServiceAddressCapt: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        fontWeight: 400,
        marginBottom: theme.px2rem(10),
        display: 'flex',
        '& > span': {
          marginRight: theme.px2rem(5),
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginBottom: theme.px2rem(15),
        },
      },
      checkbox: {
        padding: 0,
      },
      checked: {
        '&$checked': {
          color: theme.colors.primary,
        },
      },
      infoBlock: {
        backgroundColor: 'rgba(64, 154, 248, 0.1)',
        borderRadius: '20px',
        padding: `${theme.px2rem(15)} ${theme.px2rem(25)}`,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginTop: theme.px2rem(15),
        },
      },
      rightHeader: {
        fontSize: theme.px2rem(24),
        color: theme.colors.black,
        marginBottom: theme.px2rem(5),
        fontWeight: 500,
        textAlign: 'center',
      },
      infoLine: {
        padding: `${theme.px2rem(14)} 0`,
        borderBottom: '2px solid #C4C4C4',
      },
      infoLineTaxes: {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          borderBottom: 'none',
        },
      },
      blockCaption: {
        color: '#828282',
        fontWeight: 500,
        fontSize: theme.px2rem(14),
        marginBottom: theme.px2rem(10),
      },
      serviceAddress: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        fontWeight: 500,
      },
      editPlanWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.px2rem(10),
      },
      membershipHeader: {
        fontSize: theme.px2rem(20),
        color: theme.colors.black,
        fontWeight: 500,
        marginRight: theme.px2rem(15),
      },
      editPlan: {
        color: theme.colors.primary,
      },
      featuresWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '65%',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
        },
      },
      featureLine: {
        fontSize: theme.px2rem(12),
        color: theme.colors.black,
      },
      priceSumWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      priceSumWrapperMob: {
        display: 'none',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'flex',
          flexDirection: 'row',
          margin: `0 0 ${theme.px2rem(15)} 0`,
        },
      },
      priceSum: {
        fontSize: theme.px2rem(21),
        color: theme.colors.black,
        fontWeight: 500,
        textAlign: 'center',
        wordBreak: 'break-word',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          marginRight: theme.px2rem(10),
        },
      },
      period: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        textAlign: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      coupon: {
        fontSize: theme.px2rem(14),
        color: theme.colors.primary,
        textTransform: 'none',
      },
      taxesCaption: {
        margin: 0,
      },
      taxesSum: {
        fontSize: theme.px2rem(16),
        color: theme.colors.black,
        fontWeight: 500,
      },
      totalCaption: {
        fontSize: theme.px2rem(20),
        color: theme.colors.black,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
      },
      totalSum: {
        fontSize: theme.px2rem(32),
        color: theme.colors.black,
        fontWeight: 500,
      },
      totalBlock: {
        marginTop: theme.px2rem(13),
      },
      totalLine: {
        marginTop: theme.px2rem(13),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      signUpButton: {
        backgroundColor: theme.colors.primary,
        fontSize: theme.px2rem(18),
        color: '#fff',
        width: '100%',
        height: theme.px2rem(45),
        margin: `${theme.px2rem(20)} 0 ${theme.px2rem(10)}`,
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness90,
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      signUpButtonMob: {
        backgroundColor: theme.colors.primary,
        fontSize: theme.px2rem(16),
        fontWeight: 500,
        color: '#fff',
        width: '100%',
        height: theme.px2rem(40),
        margin: `${theme.px2rem(5)} 0 ${theme.px2rem(5)}`,
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      notesCaption: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        fontWeight: 500,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontWeight: 600,
          fontSize: theme.px2rem(14),
        },
      },
      noteText: {
        fontSize: theme.px2rem(14),
        color: theme.colors.black,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(12),
        },
      },
      note: {
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      noteMob: {
        margin: `${theme.px2rem(20)} 0 ${theme.px2rem(30)}`,
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      ccIcon: {
        width: theme.px2rem(104),
        height: theme.px2rem(21),
        position: 'absolute',
        right: theme.px2rem(10),
        top: '45%',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      noMarginTop: {
        marginTop: 0,
      },
      spinner: {
        marginLeft: theme.px2rem(5),
        color: '#fff',
      },
      graySpinner: {
        color: 'rgba(0, 0, 0, 0.26)',
      },
      paymentBlock: {
        border: `1px solid ${theme.colors.onevisionBlack20}`,
        borderRadius: '20px',
        padding: theme.spacing(2),
      },
      paymentBlockTitle: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      radioColor: {
        color: '#0E4595 !important',
      },
      newCard: {
        color: '#2196F3',
        fontWeight: 700,
      },
      displayNone: {
        display: 'none',
      },
      addNewCard: {
        marginLeft: theme.px2rem(39),
        marginTop: theme.px2rem(9),
        marginBottom: theme.px2rem(9),
      },
      italic: {
        fontStyle: 'italic',
        color: theme.colors.black,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(12),
        },
      },
    };
  },
  { name: 'af-checkout-page' },
);
