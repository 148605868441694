import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { Typography } from '@mui/material';
import { ProductOffered } from 'core/types';
import { useAfSelector } from 'core/redux';
import { useStyles } from './af-features-list.styles';

const sanitize = (text: string) =>
  sanitizeHtml(text, {
    allowedTags: ['br', 'strike'],
  });

const getText = (isShown: boolean, text: string) => {
  const classes = useStyles();

  return isShown ? (
    <div className={classes.featureLineContainer}>
      <div className={classes.checkMark}>✓</div>
      <Typography
        className={classes.featureLine}
        dangerouslySetInnerHTML={{
          __html: `${text}`,
        }}
      />
    </div>
  ) : null;
};

export const AfFeaturesList: React.FC = () => {
  const plan = useAfSelector(
    (state) => state.membershipSteps.plan,
  ) as ProductOffered;

  const membershipFeatures = useAfSelector(
    (state) => state.afData.membershipFeatures,
  );

  return (
    <>
      {membershipFeatures.map((feature) => {
        const activeFeature = feature.activeMembershipFeatures.find(
          (activeFeature) =>
            activeFeature.productNameId === plan.productId,
        );
        return getText(
          !!activeFeature,
          sanitize(
            activeFeature?.overrideContent || feature.defaultContent,
          ),
        );
      })}
    </>
  );
};
