import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../core/theme';

export const useStyles = makeStyles((theme: ExtTheme) => {
  return {
    root: {
      height: '100%',
      width: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: `${theme.px2rem(20)} 0`,
    },
    line: {
      height: theme.px2rem(30),
      borderRadius: '2px',
    },
    contentBlock: {
      borderRadius: '8px',
      border: `1px solid ${theme.colors.onevisionBlack20}`,
      padding: theme.px2rem(15),
      marginBottom: theme.px2rem(15),
    },
    circle: {
      width: theme.px2rem(23),
      height: theme.px2rem(23),
      marginRight: theme.px2rem(10),
    },
    bottom: {
      width: '100%',
      marginTop: theme.px2rem(25),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});
