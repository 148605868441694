import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { useStyles } from './stepper-skeleton.styles';

export const StepperSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.stepBlock}>
        <Skeleton variant="circular" className={classes.stepCircle} />
        <Skeleton variant="text" className={classes.stepName} />
      </Box>

      <Box className={classes.stepBlock}>
        <Skeleton variant="text" className={classes.divider} />
        <Skeleton variant="circular" className={classes.stepCircle} />
        <Skeleton variant="text" className={classes.stepName} />
      </Box>

      <Box className={classes.stepBlock}>
        <Skeleton variant="text" className={classes.divider} />
        <Skeleton variant="circular" className={classes.stepCircle} />
        <Skeleton variant="text" className={classes.stepName} />
      </Box>

      <Box className={classes.stepBlock}>
        <Skeleton variant="text" className={classes.divider} />
        <Skeleton variant="circular" className={classes.stepCircle} />
        <Skeleton variant="text" className={classes.stepName} />
      </Box>
    </Box>
  );
};
