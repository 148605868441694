import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      footer: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      faq: {
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.px2rem(20)} 10%`,
        backgroundColor: '#e3e3e3',
      },
      faqHeader: {
        color: theme.colors.onevisionBlack,
        fontSize: theme.px2rem(24),
        fontWeight: 600,
      },
      textChunk: {
        margin: `${theme.px2rem(15)} 0`,
      },
      question: {
        color: theme.colors.onevisionBlack,
        fontSize: theme.px2rem(14),
        fontWeight: 500,
      },
      answer: {
        color: theme.colors.onevisionBlack,
        fontSize: theme.px2rem(14),
        lineHeight: theme.px2rem(24),
      },
      help: {
        backgroundColor: '#041B48',
        padding: `${theme.px2rem(70)} 8%`,
      },
      girlIconWrapper: {
        marginTop: theme.px2rem(15),
        display: 'flex',
        flexDirection: 'row',
      },
      girlIcon: {
        width: theme.px2rem(60),
        height: theme.px2rem(60),
        borderRadius: '50%',
      },
      chatButton: {
        color: theme.colors.primary,
        marginLeft: theme.px2rem(15),
        height: '50%',
        alignSelf: 'center',
        textDecoration: 'underline',
      },
    };
  },
  { name: 'af-footer' },
);
