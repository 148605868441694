import React from 'react';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StarIcon from '@mui/icons-material/Star';
import { csn } from '@one-vision/utils';
import { ProductOffered } from '../../../../../../core/types';
import { priceDecoration } from 'core/utils';
import { useStyles } from './af-mob-option-button.styles';
import AddIcon from '@mui/icons-material/Add';
import { AFMembershipFeatureList } from '../af-membership-feature-list';

export const AfMobOptionButtonView: React.FC<{
  name: string;
  fee: number;
  isYearly: boolean;
  recommended: boolean;
  recommendedText: string;
  active: boolean;
  productOffer: ProductOffered;
  callForPricing: string | null;
  onClick: () => void;
}> = ({
  name,
  fee,
  isYearly,
  active,
  recommended,
  recommendedText,
  callForPricing,
  productOffer,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.optionWrapper}>
      <Accordion
        className={classes.accordion}
        expanded={active}
        onClick={onClick}
      >
        <AccordionSummary
          classes={{
            root: classes.summary,
            content: classes.summaryContent,
            expanded: classes.summaryExpanded,
          }}
        >
          <Button
            classes={{
              root: csn(classes.optionButton, [
                classes.optionButtonActive,
                active,
              ]),
            }}
          >
            <Box
              className={csn(classes.optionButtonLeft, [
                classes.optionButtonLeftContact,
                callForPricing,
              ])}
            >
              {active ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              <Box display="flex" flexDirection="column">
                <Box>{name}</Box>
                <Box
                  className={csn(classes.optionButtonPrice, [
                    classes.optionButtonPriceContact,
                    callForPricing,
                  ])}
                >
                  {callForPricing ? (
                    <>
                      <Box>Contact Us</Box>
                      <Box>{callForPricing}</Box>
                    </>
                  ) : (
                    <>
                      <Box>{`$${priceDecoration(fee)}`}</Box>
                      <Box>{isYearly ? '/yr' : '/mo'}</Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              <AddIcon />
            </Box>
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <AFMembershipFeatureList productOffer={productOffer} />
        </AccordionDetails>
      </Accordion>
      {!!recommended && (
        <Box className={classes.recommendationLabel}>
          <Box className={classes.recommend}>
            <StarIcon />
            <Box>{recommendedText}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
