import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      couponLine: {
        padding: `${theme.px2rem(10)} 0`,
        borderBottom: '2px solid #C4C4C4',
      },
      coupon: {
        fontSize: theme.px2rem(14),
        color: theme.colors.primary,
        textTransform: 'none',
      },
      couponSum: {
        fontSize: theme.px2rem(16),
        color: theme.colors.black,
        fontWeight: 500,
      },
      couponInputWrapper: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D2D3D3',
        borderRadius: '8px',
        display: 'flex',
        width: '50%',
        justifyContent: 'space-between',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
        },
      },
      couponInput: {
        fontSize: theme.px2rem(14),
        color: theme.colors.onevisionBlack65,
        padding: `0 ${theme.px2rem(5)}`,
      },
      couponInputWithText: {
        backgroundColor: '#FFF',
      },
      applyCoupon: {
        color: theme.colors.onevisionBlack,
      },
      chip: {
        backgroundColor: theme.colors.primary,
        filter: theme.brightness.brightness135,
        color: '#FFF',
      },
    };
  },
  { name: 'af-coupon-input' },
);
