import React, { useCallback } from 'react';
import { csn } from '@one-vision/utils';
import { Box, Typography, Button } from '@mui/material';
import { MembershipSteps } from '../../../../../core/redux/slices/membership-step.redux';
import { Answers } from '../../../../../core/redux/slices/answers.redux';
import { useStyles } from './af-help-step.styles';

export const HelpStepView: React.FC<{
  question: string;
  listButtons: Array<{
    answer: Answers;
    name: string;
    onClick: () => void;
  }>;
  currentAnswer: Answers;
  currentStep: MembershipSteps;
  setStep: (step: MembershipSteps) => void;
}> = ({ question, listButtons, currentAnswer, currentStep, setStep }) => {
  const classes = useStyles();

  const optionsStepHandler = useCallback(
    () => setStep(MembershipSteps.Options),
    [setStep],
  );

  const backHandler = useCallback(
    () => setStep(currentStep - 1),
    [currentStep, setStep],
  );

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.header}>
        Tell us a little about you.
      </Typography>
      <Typography variant="subtitle1" className={classes.headerCaption}>
        Choose the answer that best fits.
      </Typography>
      <div className={classes.divider} />
      <Typography variant="h2" className={classes.headerQuestion}>
        {question}
      </Typography>
      <Box className={classes.buttonsWrapper}>
        {listButtons.map((el) => (
          <Button
            classes={{
              root: csn(classes.button, [
                classes.answerIsChosen,
                currentAnswer === el.answer,
              ]),
            }}
            key={el.name}
            onClick={() => el.onClick()}
          >
            {el.name}
          </Button>
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          data-testid="options-show-button"
          classes={{
            root: csn(classes.showButton, [
              classes.stepHidden,
              currentStep !== MembershipSteps.HelpMe3,
            ]),
          }}
          disabled={currentAnswer === Answers.nullable}
          onClick={optionsStepHandler}
        >{`Show me what you recommend >`}</Button>
      </Box>
      <Button
        classes={{ root: classes.backButton }}
        onClick={backHandler}
      >{`< Back`}</Button>
    </Box>
  );
};
