import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      emailInputWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.px2rem(10),
      },
      input: {
        marginRight: theme.px2rem(10),
        borderRadius: '8px',
        backgroundColor: '#F5F5F5',
        padding: `${theme.px2rem(5)} ${theme.px2rem(10)}`,
        width: '40%',
        '&>div': {
          borderRadius: '8px',
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '80%',
        },
      },
      iconInfo: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      tooltip: {
        backgroundColor: theme.colors.darkBlue,
      },
      tooltipArrow: {
        color: theme.colors.darkBlue,
      },
      textDiv: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        '& > p': {
          fontSize: theme.px2rem(12),
          fontWeight: 400,
          fontStyle: 'italic',
        },
      },
      spinner: {
        marginLeft: theme.spacing(1),
        color: '#fff',
      },
      graySpinner: {
        color: 'rgba(0, 0, 0, 0.26)',
      },
      sendCodeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        width: '60%',
        height: '80%',
        maxWidth: theme.px2rem(636),
        maxHeight: theme.px2rem(620),
        padding: theme.spacing(2),
        borderRadius: '20px',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '80%',
          maxHeight: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      },
      header: {
        color: theme.colors.black,
        fontSize: theme.px2rem(27),
        fontWeight: 700,
        marginBottom: theme.px2rem(20),
      },
      text: {
        textAlign: 'center',
        color: theme.colors.onevisionBlack65,
        fontSize: theme.px2rem(16),
        fontWeight: 400,
      },
      iconShield: {
        color: '#FFB402',
        height: theme.px2rem(146),
        width: theme.px2rem(117),
        marginBottom: theme.spacing(4),
      },
      sendCodeButton: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        width: '60%',
        backgroundColor: theme.colors.secondary,
        color: '#FFFFFF',
        '&:hover': {
          filter: theme.brightness.brightness90,
          backgroundColor: theme.colors.secondary,
        },
      },
      codeInputBlock: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.px2rem(30),
        justifyContent: 'center',
        alignItems: 'center',
        '& input': {
          border: 'none !important',
        },
      },
      inputCharacter: {
        border: 'none',
        borderBottom: `2px solid ${theme.colors.onevisionBlack65}`,
      },
      characterInactive: {
        backgroundColor: 'transparent',
      },
      characterSelected: {
        borderRadius: '3px',
      },
      errorWrap: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.px2rem(5),
      },
      errorIcon: {
        color: '#E53935',
        marginRight: theme.px2rem(10),
      },
      codeTextBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.px2rem(30),
      },
      codeText: {
        color: theme.colors.black,
        fontSize: theme.px2rem(14),
        fontWeight: 400,
      },
      resendButton: {
        color: theme.colors.secondary,
        padding: 0,
        textDecoration: 'underline',
        marginLeft: theme.px2rem(5),
      },
      resendWrap: {
        display: 'flex',
        flexDirection: 'row',
      },
      backButton: {
        color: theme.colors.secondary,
        position: 'absolute',
        left: theme.px2rem(15),
        top: theme.px2rem(15),
      },
      inputError: {
        border: `1px solid ${theme.colors.borderErrorColor}`,
      },
    };
  },
  { name: 'af-confirmation-page' },
);
