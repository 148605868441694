export enum PartnerGroupRule {
  AccessToProvisionProjects = 'access_to_provision_projects',
  AccessToSubscriptionManagementPlus = 'access_to_subscription_management_plus',
  PreventChargeClientsPreLits = 'prevent_charge_clients_prelits',
}

export interface PartnerConfigRule {
  partnerGroupRuleId: string;
  value: string;
}
