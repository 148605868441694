export interface ProductOffered {
  ovbid: string;
  ovpid: number;
  productId: number;
  stripeProductId: string;
  productName: string;
  productOffered: boolean;
  priceInCents: number;
  pricePointId: string;
  buyNow: boolean;
  buyNowPriceInCents: number;
  buyNowPricePointId: string;
  annualPriceInCents: number;
  annualPricePointId: string;
  annualBuyNow: boolean;
  annualBuyNowInCents: number;
  annualBuyNowPricePointId: string;
  callForPricing: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface MembershipFeatureBundle {
  membershipFeatureId: string;
  partnerId: number;
  brandId: string;
  name: string;
  defaultContent: string;
  defaultTooltip: string | null;
  sortOrder: number;
  createdAt: string;
  updatedAt: string;
  activeMembershipFeatures: {
    activeMembershipFeatureId: string;
    membershipFeatureId: string;
    partnerId: number;
    productNameId: number;
    overrideContent: string | null;
    overrideTooltip: string | null;
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface ActivationFlowData {
  partnerId: number;
  brandDisplayName: string;
  litsDate: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  addressId: string;
  topLevelProjectStage: TopLevelProjectStage;
  brandId: string;
  address1: string;
  address2: string;
  businessName: string;
  city: string;
  state: string;
  zip: number;
  serviceLevel: string;
  tosAccepted: boolean;
  creditCardProvided: boolean;
  desiredMembership: string;
  cppExpDate: string | null;
  coupon: string | null;
  backgroundImage: string;
  primaryColor: string;
  secondaryColor: string;
  buyNow: boolean;
  annual: boolean;
  productsOffered: ProductOffered[];
  callForPricingPhone: string;
  supportPhone: string;
  signaturePhone: string;
  supportEmail: string;
  signatureEmail: string;
  subscriptionId: string | null;
  calendlyUrl: string;
  websiteUrl: string;
  tosUrl: string;
  activeSubscription: boolean;
  logo: string;
  isTrialing: boolean;
  recommendationEngine: boolean;
  membershipFeatures: MembershipFeatureBundle[];
}

export enum TopLevelProjectStage {
  PROPOSAL = 1,
  ACTIVE_PROJECT = 2,
  SERVICE_CLIENT = 3,
  NONE = 0,
}
