import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      chatView: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '90%',
        },
      },
      frame: {
        width: '100%',
        height: '100%',
      },
      hideFrame: {
        visibility: 'hidden',
        width: 0,
      },
    };
  },
  { name: 'af-chat-frame' },
);
