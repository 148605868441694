import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import VerificationInput from 'react-verification-input';
import { useAfSelector, actions } from 'core/redux';
import { getToken } from 'core/utils';
import { csn } from '@one-vision/utils';
import { API } from 'core/api';
import { useStyles } from './af-confirm-identity-modal.styles';

const SNACKBAR_TEXT = 'A verification code has been sent to';
const CODE_LENGTH = 6;

export const AfConfirmIdentityModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onValidationSubmit: (isValidated: boolean) => void;
}> = ({ isOpen, onClose, onValidationSubmit }) => {
  const classes = useStyles();

  const afData = useAfSelector((state) => state.afData);

  const ref = useRef<HTMLButtonElement | null>(null);

  const [email, setEmail] = useState(afData.email);
  const [firstStep, setFirstStep] = useState(true);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState<string>();

  const dispatch = useDispatch();

  const emailInputHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      if (error) {
        setError(false);
      }
    },
    [setEmail, error],
  );

  const submitCodeStep = useCallback(async () => {
    if (email) {
      setIsLoading(true);
      try {
        await API.requestAfCode({ email });
        dispatch(
          actions.updateSnackbar({
            text: `${SNACKBAR_TEXT} ${email}`,
            type: 'info',
          }),
        );
        setFirstStep(false);
      } catch {
        dispatch(
          actions.updateSnackbar({
            text: 'Sorry, this email address is not valid for the account. Please contact us if you need help.',
            type: 'error',
          }),
        );
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [setFirstStep, setIsLoading, email]);

  useEffect(() => {
    if (code?.length === CODE_LENGTH && ref.current) {
      ref.current.focus();
    }
  }, [code]);

  const inputHandler = useCallback(
    (e: string) => {
      if (error) {
        setError(false);
      }
      setCode(e);
    },
    [code, error],
  );

  const submitHandler = useCallback(async () => {
    if (code) {
      setIsLoading(true);
      const token = getToken();
      if (!token) {
        throw new Error('Can`t receive token');
      }

      try {
        const {
          data: {
            data: { isCodeValid },
          },
        } = await API.sendAfCode({
          code,
          token,
        });
        if (!isCodeValid) {
          throw new Error();
        }
        onValidationSubmit(true);
        setFirstStep(true);
        onClose();
      } catch {
        onValidationSubmit(false);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [setIsLoading, code, onValidationSubmit, setError, setFirstStep]);

  const backHandler = useCallback(() => {
    setFirstStep(true);
    setCode('');
  }, [setFirstStep, setCode]);

  return (
    <Modal className={classes.root} open={isOpen} onClose={onClose}>
      <Box className={classes.sendCodeWrapper}>
        {!firstStep && (
          <Button className={classes.backButton} onClick={backHandler}>
            Back
          </Button>
        )}
        <LocalPoliceOutlinedIcon className={classes.iconShield} />
        <>
          {firstStep ? (
            <>
              <Typography className={classes.header}>
                Let’s confirm your identity.
              </Typography>
              <Typography className={classes.text}>
                Protecting your personal information is our top priority.
              </Typography>
              <Typography className={classes.text}>
                Please click below and we will send a verification code to
                your email:
              </Typography>
              <Box className={classes.emailInputWrapper}>
                <TextField
                  variant="standard"
                  classes={{
                    root: csn(classes.input, [classes.inputError, error]),
                  }}
                  value={email}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreateOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={error}
                  onChange={emailInputHandler}
                />
                <Tooltip
                  title={
                    <div className={classes.textDiv}>
                      Not the email you want to use? Update your email* and
                      we will send a secure link to upgrade your
                      membership. <br />
                      <p>*Only eligible email addresses apply</p>
                    </div>
                  }
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  placement="right"
                  arrow
                >
                  <InfoOutlinedIcon className={classes.iconInfo} />
                </Tooltip>
              </Box>
              <Button
                disabled={!email || isLoading}
                onClick={submitCodeStep}
                className={classes.sendCodeButton}
              >
                Send code
                {isLoading ? (
                  <CircularProgress
                    classes={{
                      root: csn(classes.spinner, [
                        classes.graySpinner,
                        isLoading,
                      ]),
                    }}
                    size="1rem"
                  />
                ) : null}
              </Button>
            </>
          ) : (
            <Box className={classes.codeInputBlock}>
              <Box>
                <VerificationInput
                  length={CODE_LENGTH}
                  validChars="0-9"
                  placeholder=""
                  classNames={{
                    character: classes.inputCharacter,
                    characterInactive: classes.characterInactive,
                    characterSelected: classes.characterSelected,
                  }}
                  value={code}
                  onChange={inputHandler}
                />
                {error && (
                  <Box className={classes.errorWrap}>
                    <ErrorOutlineIcon className={classes.errorIcon} />
                    <FormHelperText error>
                      Incorrect verification code.
                    </FormHelperText>
                  </Box>
                )}
              </Box>
              <Box className={classes.codeTextBlock}>
                <Typography className={classes.codeText}>
                  It may take a minute to receive your code.
                </Typography>
                <Box className={classes.resendWrap}>
                  <Typography className={classes.codeText}>
                    Haven’t received your code?
                  </Typography>
                  <Button
                    className={classes.resendButton}
                    onClick={submitCodeStep}
                  >
                    Resend it
                  </Button>
                </Box>
              </Box>
              <Button
                ref={ref}
                className={classes.sendCodeButton}
                disabled={code?.length !== CODE_LENGTH || isLoading}
                onClick={submitHandler}
              >
                {isLoading ? 'Loading' : 'Submit'}
              </Button>
            </Box>
          )}
        </>
      </Box>
    </Modal>
  );
};
