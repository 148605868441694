import {
  ActivationFlowData,
  Csm,
  ProductOffered,
  TopLevelProjectStage,
} from './types';
import { dateHelper } from '@one-vision/utils';

export const checkMembership = (membership: Csm) =>
  [
    Csm['Essentials Plus'],
    Csm.Priority,
    Csm.Proactive,
    Csm.Signature,
  ].includes(membership);

export const stringToDate = (date: string) =>
  new Date(dateHelper.addTimePart(date));

export const priceDecoration = (price: number) =>
  `${price % 1 ? price.toFixed(2) : price}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  );

const getIsBuyNow = (
  product: ProductOffered,
  data: ActivationFlowData,
) => {
  const today = new Date().setHours(0, 0, 0, 0).valueOf();

  return (
    product.buyNow &&
    (stringToDate(data.litsDate).valueOf() >= today ||
      stringToDate(data.cppExpDate || '').valueOf() > today ||
      data.topLevelProjectStage === TopLevelProjectStage.PROPOSAL ||
      data.topLevelProjectStage === TopLevelProjectStage.ACTIVE_PROJECT)
  );
};

export const priceHandler = (
  data: ActivationFlowData,
  product: ProductOffered,
  isYearly: boolean,
) => {
  const isBuyNow = getIsBuyNow(product, data);

  if (isYearly) {
    return isBuyNow
      ? product.annualBuyNowInCents / 100
      : product.annualPriceInCents / 100;
  }

  return isBuyNow
    ? product.buyNowPriceInCents / 100
    : product.priceInCents / 100;
};

export const pricePointIdHandler = (
  data: ActivationFlowData,
  product: ProductOffered,
  isYearly: boolean,
) => {
  const isBuyNow = getIsBuyNow(product, data);

  if (isYearly) {
    return isBuyNow
      ? product.annualBuyNowPricePointId
      : product.annualPricePointId;
  }

  return isBuyNow ? product.buyNowPricePointId : product.pricePointId;
};

export const checkBuyNow = (
  data: ActivationFlowData,
  firstText: string,
  secondText: string,
  hasPreventChargeClientsPreLitsRule: boolean,
) => {
  const today = new Date().setHours(0, 0, 0, 0).valueOf();
  const litsDate = stringToDate(data.litsDate || '');
  const cppDate = stringToDate(data.cppExpDate || '');

  const isLitsDateExpired =
    data.litsDate === null ||
    (data.litsDate && litsDate.valueOf() < today);
  const isCppDateExpired =
    data.cppExpDate === null ||
    (data.cppExpDate && cppDate.valueOf() <= today);

  if (data.topLevelProjectStage !== TopLevelProjectStage.SERVICE_CLIENT) {
    return secondText;
  }

  if (hasPreventChargeClientsPreLitsRule && !isLitsDateExpired) {
    return `${firstText} ${litsDate.toLocaleDateString()}`;
  }

  if (!isCppDateExpired) {
    return `${firstText} ${cppDate.toLocaleDateString()}`;
  }

  return false;
};

export const sumDecoration = (sum: number) =>
  sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const getStructuredError = (title: string, detail?: string) => ({
  response: {
    data: {
      errors: [{ title, detail: detail ? detail : title }],
    },
  },
});

export const getToken = () => {
  const url = new URL(document.URL);
  const token = url.pathname.split('/')[1];

  return token;
};

export const isSubscriptionInvalid = (data: ActivationFlowData) => {
  const hasDeclined =
    data.desiredMembership === Csm['Declined Membership'] &&
    data.tosAccepted;
  const hasValidMembership =
    checkMembership(data.desiredMembership as Csm) &&
    data.creditCardProvided &&
    data.tosAccepted;
  const hasActiveSubscription =
    checkMembership(data.serviceLevel as Csm) && data.activeSubscription;
  const hasEndedActiveSubscription =
    !data.activeSubscription &&
    checkMembership(data.desiredMembership as Csm) &&
    data.creditCardProvided &&
    data.tosAccepted;

  return (
    hasDeclined ||
    hasValidMembership ||
    hasActiveSubscription ||
    hasEndedActiveSubscription
  );
};
