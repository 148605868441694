import React from 'react';
import { FormikContextType } from 'formik';
import {
  Box,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { csn } from '@one-vision/utils';
import { AfInput } from '../af-input';
import { stateList } from '../../../core/constants';
import { useStyles } from './af-location-data.styles';

interface Props {
  // We don't know the type of the fields in the form
  // eslint-disable-next-line
  formik: FormikContextType<any>;
}

export const AfLocationDataView: React.FC<Props> = ({ formik }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.inputLine}>
        <Box className={classes.inputSingle}>
          <AfInput
            formik={formik}
            label="Address 1"
            type="text"
            name="address1"
          />
        </Box>
      </Box>
      <Box className={classes.inputLine}>
        <Box className={classes.inputSingle}>
          <AfInput
            formik={formik}
            label="Address 2"
            type="text"
            name="address2"
          />
        </Box>
      </Box>
      <Box className={classes.inputLine}>
        <Box className={classes.inputTriple}>
          <AfInput formik={formik} label="City" type="text" name="city" />
        </Box>
        <Box className={classes.inputTriple}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <InputLabel className={classes.labelStyled}>State</InputLabel>
            <FormHelperText
              disabled={
                !formik.touched['state'] && !formik.errors['state']
              }
              error={formik.touched['state'] && !!formik.errors['state']}
              className={classes.formHelperText}
            >
              {formik.touched['state'] && formik.errors['state']}
            </FormHelperText>
          </Box>
          <Select
            variant="filled"
            value={formik.values['state']}
            name="state"
            className={csn(classes.inputStyled, classes.select, [
              classes.errorSelect,
              formik.touched['state'] && !!formik.errors['state'],
            ])}
            IconComponent={(props) => (
              <KeyboardArrowDown
                {...props}
                classes={{ root: classes.selectArrow }}
              />
            )}
            onChange={formik.handleChange}
          >
            {stateList.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.inputTriple}>
          <AfInput
            formik={formik}
            label="Postal code"
            type="text"
            name="zip"
          />
        </Box>
      </Box>
    </>
  );
};
