import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      header: {
        fontSize: theme.px2rem(24),
        fontWeight: 600,
        textAlign: 'center',
        color: theme.colors.black,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(20),
        },
      },
      headerText: {
        fontSize: theme.px2rem(24),
        maxWidth: theme.px2rem(622),
        fontWeight: 400,
        textAlign: 'center',
        color: theme.colors.black,
        marginTop: theme.px2rem(50),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(20),
        },
      },
      intermediateStep: {
        margin: `${theme.px2rem(50)} 0`,
      },
      chooseButtonsWrapper: {
        marginTop: theme.px2rem(50),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          width: '100%',
          flexDirection: 'column',
        },
      },
      choiceButton: {
        width: theme.px2rem(291),
        height: theme.px2rem(75),
        margin: theme.px2rem(10),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          margin: `${theme.px2rem(10)} 0`,
          width: '100%',
          height: theme.px2rem(45),
        },
      },
      helpButton: {
        backgroundColor: theme.colors.primary,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness90,
        },
      },
      optionsButton: {
        border: `1px solid ${theme.colors.secondary}`,
        color: '#000',
        '&:hover': {
          filter: theme.brightness.brightness90,
        },
      },
    };
  },
  { name: 'af-choose-way' },
);
