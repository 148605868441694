import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerGroupRule } from 'core/types';

export interface ConfigRule {
  partnerGroupRuleId: PartnerGroupRule;
  value: boolean;
}

export const partnerConfigSlice = createSlice({
  name: 'partnerConfig',
  initialState: [] as ConfigRule[],
  reducers: {
    updatePartnerConfig: (state, action: PayloadAction<ConfigRule[]>) => [
      ...state,
      ...action.payload,
    ],
  },
});
