import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      customStepper: {
        width: '100%',
        padding: '0',
      },
      customStepperHidden: {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'flex',
        },
      },
      stepIconRoot: {
        display: 'flex',
        alignItems: 'center',
        width: theme.px2rem(20),
        justifyContent: 'center',
        color: theme.palette.grey[300],
        paddingTop: theme.px2rem(4),
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.px2rem(8),
        },
      },
      stepIconCompleted: {
        color: '#fff',
        zIndex: 1,
        fontSize: 'small',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          color: theme.colors.secondary,
          fontSize: 'large',
        },
      },
      stepIconCircle: {
        width: theme.px2rem(18),
        height: theme.px2rem(18),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[300],
        [theme.breakpoints.down('md')]: {
          width: theme.px2rem(14),
          height: theme.px2rem(14),
        },
      },
      stepIconCircleFilled: {
        backgroundColor: theme.colors.secondary,
        [theme.breakpoints.down('md')]: {
          backgroundColor: 'transparent',
        },
      },
      stepIconCircleActiveFilled: {
        backgroundColor: theme.colors.secondary,
      },
      stepperText: {
        color: theme.colors.secondary,
        fontSize: theme.px2rem(11),
        fontWeight: 'normal',
      },
      stepperLabelHidden: {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      customConnector: {
        [theme.breakpoints.down('md')]: {
          border: `2px solid #bdbdbd`,
        },
      },
      customConnectorActive: {
        [theme.breakpoints.down('md')]: {
          '& .MuiStepConnector-line': {
            border: `2px solid ${theme.colors.secondary}`,
          },
        },
      },
    };
  },
  { name: 'af-root' },
);
