import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'row',
        color: 'black',
        marginBottom: theme.spacing(0.8),
      },
      ccImage: {
        height: theme.px2rem(60),
        width: theme.px2rem(60),
      },
      cell: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2),
      },
      details: {
        alignItems: 'start',
      },
      ccDetails: {
        fontSize: theme.px2rem(12),
        fontWeight: 700,
      },
      ccExp: {
        fontSize: theme.px2rem(10),
        fontWeight: 400,
        color: theme.colors.onevisionBlack65,
      },
      chip: {
        backgroundColor: 'rgba(64, 154, 248, 0.1)',
        fontWeight: 500,
      },
    };
  },
  { name: 'af-payment-method-item' },
);
