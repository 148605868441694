import { makeStyles } from '@mui/styles';
import { ExtTheme } from '../../../core/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        color: theme.colors.black,
        margin: `${theme.px2rem(30)} ${theme.px2rem(15)}`,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      header: {
        fontSize: theme.px2rem(32),
        fontWeight: 500,
        marginBottom: theme.px2rem(15),
        textAlign: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(18),
          textAlign: 'left',
          fontWeight: 600,
        },
      },
      paragraph: {
        fontSize: theme.px2rem(15),
        fontWeight: 400,
        marginBottom: theme.px2rem(5),
        textAlign: 'center',
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(13),
          textAlign: 'left',
          marginBottom: theme.px2rem(15),
        },
      },
      cardWrapper: {
        margin: `${theme.px2rem(25)} 0`,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          margin: `${theme.px2rem(20)} 0`,
        },
      },
      card: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow:
          '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
        borderRadius: '20px',
        padding: `${theme.px2rem(20)} ${theme.px2rem(90)} ${theme.px2rem(
          25,
        )}`,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          padding: `${theme.px2rem(20)} 0`,
          width: '100%',
          boxShadow: 'none',
          alignItems: 'flex-start',
          borderTop: '1px solid rgba(128, 128, 128, 0.25)',
          borderBottom: '1px solid rgba(128, 128, 128, 0.25)',
          borderRadius: 0,
        },
      },
      serviceTeamCaption: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        marginBottom: theme.px2rem(5),
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: theme.px2rem(350),
        [theme.breakpoints.down('md')]: {
          maxWidth: theme.px2rem(200),
        },
      },
      logoIcon: {
        marginBottom: theme.px2rem(45),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      cardLine: {
        fontSize: theme.px2rem(16),
        fontWeight: 500,
        marginBottom: theme.px2rem(5),
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          fontSize: theme.px2rem(12),
          fontWeight: 400,
          alignItems: 'left',
        },
      },
      cardLineIcon: {
        marginRight: theme.px2rem(10),
        color: '#828282',
      },
      bottomText: {
        alignItems: 'center',
        textAlign: 'center',
        fontSize: theme.px2rem(14),
      },
      mobConfirmIcon: {
        width: theme.px2rem(180),
        height: theme.px2rem(140),
        [theme.breakpoints.up(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      upgradeButton: {
        backgroundColor: theme.colors.primary,
        alignSelf: 'center',
        color: '#fff',
        width: '35%',
        height: theme.px2rem(40),
        marginTop: theme.px2rem(20),
        '&:hover': {
          backgroundColor: theme.colors.primary,
          filter: theme.brightness.brightness95,
        },
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          height: theme.px2rem(70),
        },
      },
      icon: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.down(theme.af.breakpoints.sm)]: {
          display: 'none',
        },
      },
      tooltip: {
        backgroundColor: theme.colors.darkBlue,
      },
      tooltipArrow: {
        color: theme.colors.darkBlue,
      },
      textDiv: {
        fontSize: theme.px2rem(14),
        fontWeight: 500,
        '& > p': {
          fontSize: theme.px2rem(12),
          fontWeight: 400,
          fontStyle: 'italic',
        },
      },
      spinner: {
        marginLeft: theme.px2rem(5),
        color: '#fff',
      },
      graySpinner: {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    };
  },
  { name: 'af-confirmation-page' },
);
